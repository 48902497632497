<!--
// Javascript
---------------->
<script>
import Header from "../../../../../providers/tiktok/templates/components/Header.svelte"
import SocialLogo from "../../../../../components/logos/Tiktok.svelte"
import { store, videos } from '../../../../../stores/widget.js'
</script>


<!--
// HTML
---------------->
<div>
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.tiktok_meta.picture}
        headerTitle={$store.tiktok_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.tiktok_meta.title}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
    <div class="ev-cards">
        {#each $videos as {picture, title, description, create_time}, i}
        <div class="ev-card-container" style="background-image: url({picture});">
            <div class="ev-card-body ev-card-grid-overlay">
                <div id="{i}" on:click class="ev-link-cover"></div>
                <div class="ev-meta">
                    <span class="ev-logo">
                        <SocialLogo />
                    </span>
                    <span class="ev-date">{create_time}</span>
                    <span class="ev-title ev-title-clamp">{title ? title : '...'}</span>
                    <span class="ev-description ev-description-clamp">{description ? description : '...'}</span>
                </div>
            </div>
            <div class="ev-meta-bottom">
                <div class="ev-meta-logo-bottom">
                    <SocialLogo />
                </div>
                <div class="ev-meta-title-bottom">
                    {title ? title : '...'}
                    <div class="ev-meta-date-bottom">{create_time}</div>
                </div>
            </div>
        </div>
        {/each}
    </div>
</div>


<!--
// Styles
---------------->
<style>
.ev-cards {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 240px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-auto-flow: dense;
}
.ev-card-container {
    position: relative;
    width: 100%;
    height: 100%;
    grid-column: 1; /* col 1 */
    border: 1px solid #EAEAEA;
    border-radius:0.8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.ev-card-container:nth-child(15n + 2),
.ev-card-container:nth-child(15n + 4) {
    grid-column: 2;    /* col 2 */
    
}
.ev-card-container:nth-child(15n + 5) {
    grid-column: 3 / 5;  /* col 3-5 */
    grid-row: span 2;
}
.ev-card-container:nth-child(15n + 6) {
    grid-column: 1 / 3;    /* col 1-3 */
    grid-row: span 2;
}
.ev-card-container:nth-child(15n + 7),
.ev-card-container:nth-child(15n + 9) {
    grid-column: 3;    /* col 3 */
}
.ev-card-container:nth-child(15n + 8),
.ev-card-container:nth-child(15n + 10) {
    grid-column: 4;    /* col 4 */
}
.ev-card-container:nth-child(15n + 11),
.ev-card-container:nth-child(15n + 14) {
    grid-column: 1; /* col 1 */
}
.ev-card-container:nth-child(15n + 12) {
    grid-column: 2 / 4; /* col 2-4 */
    grid-row: span 2;
}
.ev-card-container:nth-child(15n + 13),
.ev-card-container:nth-child(15n + 15) {
    grid-column: 4; /* col 5 */
}
.ev-card-grid-overlay {
    position: relative;
    transition: .5s ease;
    border-radius:0.8rem;
    height:100%;
    opacity: 0;
    z-index: 2;
}
.ev-link-cover{
    background: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    /* z-index: 999; */
    border-radius:0.8rem;
    cursor: pointer;
}
.ev-card-grid-overlay:hover {
    background: rgba(0,0,0,0.9);	
}
.ev-card-container:hover .ev-card-grid-overlay {
    opacity: 1;
}
.ev-card-body{
    display: grid;
    text-align: center;
    /* padding: 20px; */
}
.ev-meta{
    margin: auto;
	word-break: break-word;
	/* add */
	padding: 20px;
}
.ev-logo{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.ev-date{
    color: rgba(255, 255, 255, 0.3);
    font-weight:300;
    font-size: small;
}
.ev-title{
    margin: 15px 0px 10px 0px;
    color: #FFFFFF;
    font-weight:300;
}
.ev-description{
    color: #FFFFFF;
    font-weight:300;
    font-size: small;
    margin-bottom: 20px;
}
.ev-title-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.ev-description-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ev-meta-bottom{
	position: absolute;
	bottom:0;
	z-index: 1;
	width:100%;
	/* padding:10px; */
	border-bottom-left-radius: 0.8rem;
	border-bottom-right-radius: 0.8rem;
	background-image:linear-gradient(180deg,transparent,#000);
    text-align: left;
}
.ev-meta-logo-bottom{
	float:right;
	/* padding: 8px 5px 0 0; */
	/* change */
	padding: 20px 15px 0 0;
	opacity: .7;
}
.ev-meta-title-bottom{
	color: rgba(255, 255, 255, 0.9);
	font-size:14px;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 75%;
	/* add */
	padding:10px;
}
.ev-meta-date-bottom{
	color: rgba(255, 255, 255, 0.6);
	font-weight:300;
	font-size: 12px;
}
@media (max-width:768px){
    .ev-cards {
        grid-template-columns: 1fr;
    }
    .ev-card-container:nth-child(15n + 2),
    .ev-card-container:nth-child(15n + 4) {
        grid-column: 1;    /* col 2 */
    }
    .ev-card-container:nth-child(15n + 5) {
        grid-column: 1;  /* col 3-5 */
        grid-row: span 1;
    }
    .ev-card-container:nth-child(15n + 6) {
        grid-column: 1;    /* col 1-3 */
        grid-row: span 1;
    }
    .ev-card-container:nth-child(15n + 7),
    .ev-card-container:nth-child(15n + 9) {
        grid-column: 1;    /* col 3 */
    }
    .ev-card-container:nth-child(15n + 8),
    .ev-card-container:nth-child(15n + 10) {
        grid-column: 1;    /* col 4 */
    }
    .ev-card-container:nth-child(15n + 11),
    .ev-card-container:nth-child(15n + 14) {
        grid-column: 1; /* col 1 */
    }
    .ev-card-container:nth-child(15n + 12) {
        grid-column: 1; /* col 2-4 */
        grid-row: span 1;
        /* background-size: 430px; */
    }
    .ev-card-container:nth-child(15n + 13),
    .ev-card-container:nth-child(15n + 15) {
        grid-column: 1; /* col 5 */
    }
    .ev-meta-bottom{
		width:100%;
		border-bottom-left-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}
}
</style>