<!--
// Scripts
---------------->
<script>
export let permalink;
export let title;
</script>


<!--
// HTML
---------------->
<div>
    <iframe 
        class="ev-video"
        src="{permalink}"
        title="{title}"
        frameborder="0"
        allow=""
        allowfullscreen
    ></iframe>
</div>


<!--
// Styles
---------------->
<style>
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
</style>