<!--
// Javascript
---------------->
<script>
import { widgetId } from '../../../../../../stores/widget.js'
// import Branding from "../../../../../../components/Branding.svelte";
import System from 'svelte-system-info';
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

// export let image;
export let permalink;
export let pageName;
export let title;
export let description;
export let createdAt;
export let orientation;

// Get users operating system
let os = '';
if((System.OSName == 'iOS') || (System.OSName == 'Android')){
    os = 'mobile'
}

let width = '';
let height = '';
if(orientation == 'portrait'){
    width = ''
    height = '672' //557
}else{
    width = 'auto'
    height = ''
}


function addVideo() {
    if(os == 'mobile'){
        setTimeout(function() { FB.XFBML.parse(document.getElementById('embedvidio-widget-' + $widgetId)) }, 500);
    }
}
</script>

<!--
// HTML
---------------->
<div class="ev-container">

    <div class="">
        <a href="#prev" on:click={() => dispatch('prevClick')} on:click={addVideo} class="ev-prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </a>
    </div>

    <div class="ev-slide">
        <div class="ev-content">
            {#if os == 'mobile'}
                <div class="ev-video-js">
                    <div id="fb-root" data-href="{permalink}"></div>
                    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"></script>
                    <!-- the width here is the issue with mobile -->
                    <div 
                        class="fb-video" 
                        data-href="{permalink}" 
                        data-autoplay="true"
                        data-width=""
                        data-height="{height}"
                        data-show-text="false" 
                        data-allowfullscreen="true" 
                    ></div>
                </div>
            {:else}
                <iframe 
                    class="ev-video-iframe"
                    src="https://www.facebook.com/plugins/video.php?width=1200&height={height}&href=https://www.facebook.com{permalink}/&show_text=false&t=0" 
                    width="auto" 
                    height="" 
                    style="border:none;overflow:hidden" 
                    scrolling="no" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    allow="autoplay" 
                    allowFullScreen="true" 
                    title="">
                </iframe>
            {/if}
        </div>
        
        <div class="ev-meta">
            <div class="ev-logo">
                <a href="https://www.facebook.com{permalink}" target="_blank">
                    <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Facebook Icon" data-v-859e6cf4="">
                        <path d="M22.5 15.4999C22.5 23.7842 23.3319 29.9999 15.0307 29.9999C6.72946 29.9999 7.00004 24.2842 7.00004 15.9999C7.00004 7.71562 8.19878 3 16.5 3C24.8012 3 22.5 7.21562 22.5 15.4999Z" fill="#ffffff"></path>
                        <path d="M15.0001 0C6.71585 0 0 6.70214 0 14.9695C0 22.3844 5.40783 28.6 12.4984 29.7891V18.0926H8.87992V13.9104H12.4984V10.8266C12.4984 7.24857 14.6882 5.29875 17.8871 5.29875C19.4191 5.29875 20.7357 5.41268 21.1179 5.46286V9.20286L18.8993 9.20393C17.16 9.20393 16.8247 10.0286 16.8247 11.2391V13.9082H20.9748L20.4335 18.0904H16.8247V29.8945C24.2463 28.9931 30 22.6156 30 14.9652C30 6.70214 23.2841 0 15.0001 0Z" fill="#1778f2"></path>
                    </svg>
                </a>
            </div>
            <div>
                <a class="ev-name" href="https://www.facebook.com{permalink}" target="_blank">
                    @{pageName}
                </a>
                <div class="ev-date">{createdAt}</div>
            </div>
            <div>
                <div class="ev-title">{title ? title : pageName}</div>
                <div class="ev-description">
                    {@html description ? description : '...'}
                </div>
            </div>
            <!-- <Branding /> -->
        </div>   
    </div>

    <div class="">
        <a href="#next" on:click={() => dispatch('nextClick')} on:click={addVideo} class="ev-next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg> 
        </a>
    </div>

</div>


<!--
// Styles
---------------->
<style>
.ev-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ev-video-js{
    min-height:557px
}
.ev-video-iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: inherit;
}
.ev-slide{
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1rem;
    padding:20px;
    background: #ffffff;
    border-radius: 10px;
}
.ev-next,
.ev-prev{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(138, 138, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    margin:0px 20px;
    opacity:.4;
    padding: 4px;
}
.ev-next:hover,
.ev-prev:hover{
    background-color: rgb(255, 255, 255);
    opacity:.8;
}
.ev-content{
    display: grid;
}
.ev-meta{
    min-width: 330px;
    max-width: 330px;
    padding: 0px 10px;
    text-align: left;
}
.ev-name{
    color: rgb(37, 99,235);
    font-size:larger;
    font-weight: 600;
}
.ev-name:hover{
    color: rgb(63, 115, 228);
}
.ev-date{
    margin-top: -2px;
    margin-left:3px;
    color: #9ca0ac;
	font-weight:300;
	font-size: small;
}
.ev-logo{
    float:right
}
.ev-title{
    color: rgb(17, 24, 39);
    font-size: 16px;
    font-weight: 600;
    margin:20px 0px 10px 0px;
}
.ev-description{
    color: rgb(107, 114, 128);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
}
@media only screen and (max-width: 1200px){
    .ev-video-js{
        min-height:204px
    }
    .ev-slide{
        grid-template-columns: 1fr;
        padding:0px;
        background: #ffffff;
    }
    .ev-next,
    .ev-prev{
        display:none;
    }
    .ev-meta{
        padding:20px;
    }
}
</style>