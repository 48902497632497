<!--
// Scripts
---------------->
<script>
import System from 'svelte-system-info';
export let permalink;
export let videoWidth;
export let videoHeight;
// Get users operating system
let os = '';
if((System.OSName == 'iOS') || (System.OSName == 'Android')){
    os = 'mobile'
}
</script>


<!--
// HTML
---------------->
{#if os == 'mobile'}
	<div class="ev-video-js">
		<div id="fb-root"></div>
		<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"></script>
		<div 
			class="fb-video" 
			data-href="{permalink}" 
			data-autoplay="true"
			data-width="{videoWidth}"
			data-height="{videoHeight}"
			data-show-text="false" 
			data-allowfullscreen="true" 
		></div>
	</div>
{:else}
	<iframe 
		class="ev-video-iframe"
		src="https://www.facebook.com/plugins/video.php?width=1200&height={videoHeight}&href=https://www.facebook.com{permalink}/&show_text=false&t=0" 
		width="auto" 
		height="" 
		style="border:none;overflow:hidden" 
		scrolling="no" 
		frameborder="0" 
		allowfullscreen="true" 
		allow="autoplay" 
		allowFullScreen="true" 
		title="">
	</iframe>
{/if}


<!--
// Styles
---------------->
<style>
.ev-video-js {
    min-height:428px; 
}
.ev-video-iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
}
@media (max-width:1200px){
    .ev-video-js {
        min-height:152px; 
    }
}
</style>