<!--
// Javascript
---------------->
<script>
import { fade } from 'svelte/transition';
import { store, videos } from '../../../../../../stores/widget.js'
import Slide from "./Slide.svelte";

export let videoShowingIndex = 0;
$: video = $videos[videoShowingIndex];

// Next button
const nextVideo = () => {
    if (videoShowingIndex === $videos.length-1) {
        videoShowingIndex = 0;
    } else {
        videoShowingIndex += 1;
    }

    if(document.querySelector('.tiktok-embed')) {
        document.querySelector('.tiktok-embed').remove()
    }
    
    var tiktokEmbed = document.createElement('script');
    tiktokEmbed.src = "https://www.tiktok.com/embed.js";
    tiktokEmbed.async = true;
    document.querySelector('#embed-tt').appendChild(tiktokEmbed);
    document.getElementById('embed-tt').innerHTML = $videos[videoShowingIndex].embed_html;
}
// Previous button
const prevVideo = () => {
    if (videoShowingIndex === 0) {
        videoShowingIndex = $videos.length-1;
    } else {
        videoShowingIndex -= 1;
    }

    if(document.querySelector('.tiktok-embed')) {
        document.querySelector('.tiktok-embed').remove()
    }
    
    var tiktokEmbed = document.createElement('script');
    tiktokEmbed.src = "https://www.tiktok.com/embed.js";
    tiktokEmbed.async = true;
    document.querySelector('#embed-tt').appendChild(tiktokEmbed);
    document.getElementById('embed-tt').innerHTML = $videos[videoShowingIndex].embed_html;
}
</script>


<!--
// HTML
---------------->
<div class="ev-lightbox" in:fade out:fade>
    <div class="ev-close" on:click>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div class="ev-wrapper">
        <div class="ev-container">
            <!-- Slide -->
            <Slide
                videoId={video.video_id}
                userName="{$store.tiktok_user}"
                description={video.description}
                publishedAt={video.create_time}
                on:prevClick={prevVideo}
                on:nextClick={nextVideo}
            />
        </div>
    </div>
</div>
<!--
// Styles
---------------->
<style>
.ev-lightbox {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ev-wrapper {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.ev-close {
    color: #999;
    position: absolute;
    top: 3%;
    right: 3%;
    width:40px;
    height:40px;
}
.ev-close:hover,
.ev-close:focus {
    color: #ddd;
    text-decoration: none;
    cursor: pointer;
}
.ev-container {
    max-width:841px;
}
@media only screen and (max-width: 1200px){
    .ev-lightbox{
        background-color: #FFFFFF;
        align-items: unset;
    }
    .ev-wrapper{
        margin:0px
    }
    .ev-close {
        color: #FFFFFF;
        background-color: #272727;
        border-radius: 50%;
        font-size: 16px;
        padding: 5px 5px;
        z-index:3;
        position: absolute;
        top: 1%;
        right: 2%;
    }
}
</style>