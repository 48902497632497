<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import System from 'svelte-system-info';
import VideoList from './VideoList.svelte'
import LightBox from '../components/lightbox/Lightbox.svelte'
import { widgetId, providerObject } from '../../../../../stores/widget.js'
// Get users operating system
let os = '';
if((System.OSName == 'iOS') || (System.OSName == 'Android')){
    os = 'mobile'
}

let modalOpen = false;
let videoShowingIndex = 0;

const handleVideoClick = (e) => {
   videoShowingIndex = Number(e.target.id);
   if(os == 'mobile'){
      setTimeout(function() { FB.XFBML.parse(document.getElementById('embedvidio-widget-' + $widgetId)) }, 500);
   }
   modalOpen = true;
}
if(os == 'mobile'){
   onMount(() => {
      window.fbAsyncInit = function() {
         FB.Event.subscribe('xfbml.ready', function(event) {
            providerObject.set(event.instance)
            $providerObject.play()
         });
      };
   })
}
</script>


<!--
// HTML
---------------->
{#if modalOpen}
   <LightBox on:click={() => modalOpen = !modalOpen} {videoShowingIndex} />
{/if}
<VideoList on:click={handleVideoClick}/>


<!--
// Styles
---------------->
<style>
</style>