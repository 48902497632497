<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import System from 'svelte-system-info';
import Header from "../../../../../providers/facebook/templates/components/Header.svelte"
import Branding from "../../../../../components/Branding.svelte";
import { store, providerObject } from '../../../../../stores/widget.js';

export let widget;
export let liveVideo;
export let branding;

// Set video height if Orientation is portrait
export let pushedLiveOrientation;
export let setLiveOrientation;
// Get users operating system
let os = '';
if((System.OSName == 'iOS') || (System.OSName == 'Android')){
    os = 'mobile'
}

// Set videos orientation
let videoHeight;
if(pushedLiveOrientation == '576'){
    videoHeight = 576
}else if(setLiveOrientation == '576'){
    videoHeight = 576
}else{
    videoHeight = ''
}

if(os == 'mobile'){
onMount(() => {
    window.fbAsyncInit = function() {
        FB.Event.subscribe('xfbml.ready', function(event) {
            if(event.id == 'live-video-player') {
                providerObject.set(event.instance)
                $providerObject.play()
            }
        });
    };
    setTimeout(function() {
        FB.XFBML.parse(document.getElementById('embedvidio-widget-' + widget))
    }, 500);
})
}

</script>


<!--
// HTML
---------------->
<!-- Header -->
{#if $store.header_data.header == 1 }
<Header 
    headerThumbnail={$store.facebook_meta.picture}
    headerTitle={$store.facebook_meta.title}
    headerTitleColor={$store.header_data.header_txt_color}
    headerAccountId={$store.facebook_page_id}
    customBtn={$store.header_data.custom_btn.active}
    customBtnText={$store.header_data.custom_btn.text}
    customBtnTextColor={$store.header_data.custom_btn.text_color}
    customBtnUrl={$store.header_data.custom_btn.url}
    customBtnColor={$store.header_data.custom_btn.color}
/>
{/if}
{#if os == 'mobile'}
    <div id="fb-root" data-href="{liveVideo}"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"></script>
    <div id="live-video-player" class="fb-video" data-href="https://www.facebook.com{liveVideo}" data-autoplay="true" data-width="auto" data-height="{videoHeight}" data-show-text="false"  data-allowfullscreen="true">
    </div>
{:else}
    <iframe 
        class="ev-video"
        src="https://www.facebook.com/plugins/video.php?width=1200&height={videoHeight}&href=https://www.facebook.com{liveVideo}/&show_text=false&t=0" 
        width="auto" 
        height="" 
        style="border:none;overflow:hidden" 
        scrolling="no" 
        frameborder="0" 
        allowfullscreen="true" 
        allow="autoplay" 
        allowFullScreen="true" 
        title="">
    </iframe>
{/if}
{#if branding == 1}
    <Branding />
{/if}


<!--
// Styles
---------------->
<style>
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
</style>