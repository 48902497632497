<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import LiveNotice from './LiveNotice.svelte';
import { store, widgetId, liveState, pusherAppKey } from '../../../../../stores/widget.js';
import Pusher from 'pusher-js';

onMount(() => {
    if($store.live_status == 0) {
        let pusher = new Pusher($pusherAppKey, {
            cluster: 'us3'
        });

        let notifications = pusher.subscribe('embedvidio-status-' + $widgetId);
            notifications.bind('online-status', function() {
            liveState.set(true);
            pusher.disconnect();
        });            
    }
})
</script>


<!--
// HTML
---------------->
<div>
{#if $store.live_status == 1 || $liveState}
    <!-- Live component -->
    <LiveNotice/>
{/if}
</div>


<!--
// Styles
---------------->