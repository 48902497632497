<!--
// Scripts
---------------->
<script>
import { store, videos } from '../../../../../stores/widget.js'
import Header from "../../../../../providers/twitch/templates/components/Header.svelte"
import SocialLogo from "../../../../../components/logos/TwitchP.svelte"
import Video from "./Video.svelte";
import VideoList from "./VideoList.svelte";

let height;
let wrapperheight;
let metaHeight;

let videoShowingIndex = 0;
$: video = $videos[videoShowingIndex];

// Thumbnail onClick
const goToVideo = (number) => videoShowingIndex = number;

function loadVideo(e) {}
</script>


<!--
// HTML
---------------->
<div>
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.twitch_meta.picture}
        headerTitle={$store.twitch_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.twitch_meta.title}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
    <div class="ev-wrapper" bind:clientHeight={wrapperheight}>
        <div class="ev-wrap">
            <div class="ev-grid">
                <div class="ev-left">
                    <div class="ev-media">
                            
                        <div bind:clientHeight={height}>
                            <Video videoId={video.video_id} title={video.title} />
                        </div>

                        <div class="ev-meta" bind:clientHeight={metaHeight}>
                            <div class="ev-meta-left">
                                <span class="ev-title ev-title-clamp">{video.title ? video.title : video.user_name}</span>
                                <span class="ev-description ev-description-clamp">{video.description ? video.description : '...'}</span>
                            </div>
                            <div class="ev-meta-right">
                                <span class="ev-logo">
                                    <a href="https://www.facebook.com{video.permalink_url}" target="_blank">
                                        <SocialLogo />
                                    </a>
                                </span>
                                <span class="ev-date">{video.published_at}</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="ev-right" style="height:{height + metaHeight + 26}px;">
                    <div class="ev-list-wrapper" style="height:{wrapperheight - 2}px">
                        <div class="ev-list-scroll" style="height:{height + metaHeight}px">
                        {#each $videos as {picture, title, user_name, description}, i}
                            <VideoList 
                                thumbImg={picture}
                                title={title} 
                                description={description} 
                                userName={user_name} 
                                selected={i === videoShowingIndex}
                                on:click={() => goToVideo(i)}
                                on:getVideo={loadVideo}
                            />
                        {/each}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--
// Styles
---------------->
<style>
.ev-wrapper {
    background-color: transparent;
}
.ev-grid {
    display: grid;
    grid-template-columns: 70fr 30fr;
    grid-gap: 1rem;
}
.ev-left{
    height: fit-content;
    background-color: #FFFFFF;
    border-radius: .8rem;
}
.ev-right{
    background-color: #FFFFFF;
    border-radius: .8rem;
    overflow: hidden;
}
.ev-media {
    padding: 10px;
    border-radius: .8rem;
    border:1px solid #EAEAEA;
}
.ev-list-wrapper{
    padding: 10px;
    border-radius: .8rem;
    border:1px solid #EAEAEA;
	line-height: 1.5;
}
.ev-list-scroll{
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-padding-bottom: 0;
}
.ev-meta{
    display: grid;
    grid-template-columns: 70fr 30fr;
    color: #000000;
	margin: auto;
    padding: 15px 10px 0 10px;
    border-top: 1px solid #EAEAEA;
    margin-top: 5px;
}
.ev-meta-left{
    text-align: left;
    display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-word;
}
.ev-title{
    color: rgb(17, 24, 39);
	font-weight:300;
    margin-bottom: 3px;
}
.ev-description{
    color: rgb(107, 114, 128);
	font-weight:300;
	font-size: 14px;
}
.ev-meta-right{
    text-align: right;
}
.ev-logo{
	display: flex;
    justify-content: right;
    padding-bottom: 3px;
}
.ev-date{
    color: #9ca0ac;
	font-weight:300;
	font-size: small;
    margin-top: 3px;
}
.ev-title-clamp {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.ev-description-clamp {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
@media (max-width:1200px){
    .ev-grid{
        grid-template-columns: 1fr;
    }
    .ev-list-wrapper{
		height: var(--wrapperheight) !important;
	}
}
</style>