<!--
// Javascript
---------------->
<script>

export let headerThumbnail;
export let headerTitle;
export let headerTitleColor;
export let headerAccountId;
export let customBtn;
export let customBtnText;
export let customBtnTextColor;
export let customBtnUrl;
export let customBtnColor;
</script>

<!--
// HTML
---------------->
<div class="ev-header">
    <div class="ev-header-meta">
        <img src="{headerThumbnail}" alt="{headerTitle}"/>
        <div>
            <!-- If custom btn false ... style margin-top: 15px; -->
            {#if customBtn == 0}
            <div class="ev-header-title" style="color:{headerTitleColor}">{headerTitle}</div>
            {:else}
            <div class="ev-header-title" style="color:{headerTitleColor}">{headerTitle}</div>
            <!-- If custom btn == true -->
            <a href="http://www.facebook.com/{headerAccountId}" class="ev-follow-btn-sm" target="_blank">Follow us</a>
            {/if}
        </div>
    </div>
    <div class="ev-buttons">
        <!-- if custom btn == false -->
        {#if customBtn == 0}
        <a href="http://www.facebook.com/{headerAccountId}" class="ev-follow-btn-lg" target="_blank">Follow us</a>
        {/if}
        <!-- if custom btn == true -->
        {#if customBtn == 1}
        <a href="{customBtnUrl ? customBtnUrl : '#'}" class="ev-custom-btn" style="background-color:{customBtnColor};color:{customBtnTextColor}" target="_blank">{customBtnText ? customBtnText : 'Button'}</a>
        {/if}
    </div>
</div>


<!--
// Styles
---------------->
<style>
.ev-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 15px 10px;
    align-items: flex-start;
}
.ev-header-meta {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    text-align: left;
    flex-grow: 1;
    align-items: center;
}
.ev-header-meta img {
    align-self: start;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #EAEAEA;
}
.ev-header-title {
    color: #374151;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ev-follow-btn-sm {
    background-color: #FFFFFF;
    border: 1px solid #d1d5db;
    color:#121727;
    padding: 2px 6px;
    text-align: center;
        text-decoration: none !important;
    display: inline-block;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    line-height: initial;
    white-space: nowrap;
}
.ev-follow-btn-lg {
    background-color:#FFFFFF;
    border: 1px solid#d1d5db;
    color:#121727;
    padding: 4px 14px;
    text-align: center;
        text-decoration: none !important;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    margin-top: 15px;
    cursor: pointer;
    white-space: nowrap;
}
.ev-follow-btn-sm:hover {
    background-color:#f9fafb;
        text-decoration: none !important;
}
.ev-follow-btn-lg:hover {
    background-color:#f9fafb;
        text-decoration: none !important;
}
.ev-buttons {
    text-align: right;
}
.ev-custom-btn {
    border: none;
    color: white;
    padding: 4px 14px;
    text-align: center;
        text-decoration: none !important;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    margin-top: 15px;
    cursor: pointer;
    white-space: nowrap;
}
.ev-custom-btn:hover {
    opacity: 0.9;
        text-decoration: none !important;
}
@media (max-width:640px){
    .ev-header {
        flex-direction: column;
        display: block;
    }
    .ev-header-title {
        width: fit-content;
        margin-top: 0px !important;
    }
    .ev-custom-btn {
        width: 100%;
    }
    .ev-follow-btn-lg {
        width: 100%;
    }
}
</style>