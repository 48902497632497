<script>
import VideoList from './VideoList.svelte'
import LightBox from '../components/lightbox/Lightbox.svelte'
import { videos } from '../../../../../stores/widget.js'

let modalOpen = false;
let videoShowingIndex = 0;

const handleVideoClick = (e) => {
    videoShowingIndex = Number(e.target.id);
    modalOpen = true;

    setTimeout(function() {
        var tiktokEmbed = document.createElement('script');
        tiktokEmbed.src = "https://www.tiktok.com/embed.js";
        tiktokEmbed.async = true;
        document.querySelector('#embed-tt').appendChild(tiktokEmbed);
        document.getElementById('embed-tt').innerHTML = $videos[videoShowingIndex].embed_html;
    }, 200);
}
</script>


<!--
// HTML
---------------->
{#if modalOpen}
    <LightBox on:click={() => modalOpen = !modalOpen} {videoShowingIndex} />
{/if}
<VideoList on:click={handleVideoClick}/>


<!--
// Styles
---------------->
<style>
</style>