<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import LiveVideo from './LiveVideo.svelte';
import Placeholder from './Placeholder.svelte';
import axios from 'axios';
import Header from "../../../../../providers/youtube/templates/components/Header.svelte"
import { store, widgetId, hiddenYtObject, liveState, currentVideo, ytLiveRefresh, appUrl } from '../../../../../stores/widget.js';

let apiLiveStatus;
let apiLivePermalink;
let ytLiveTimer;

let tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
let firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


/**
 * OnMount
 ************************************************/ 
onMount(() => {
    (async() => {
        while(typeof YT == 'undefined')
        await new Promise(resolve => setTimeout(resolve, 1000));
        // This is setting the hiddenPlayer to the iFrame below via id youtube-hidden-embed-' + $widgetId
        let hiddenPlayer = new YT.Player('youtube-hidden-embed-' + $widgetId, {
            events: {
                'onReady': function() {
                    checkYoutubeStatus();
                }
            },
        });

        // This is where the "hiddenYtObject" gets set.
        hiddenYtObject.set(hiddenPlayer);
        await new Promise(resolve => setTimeout(resolve, 1000));
        initCheckLive();

    })();
})

/**
 * Check to see if YT channel is live
 ************************************************/ 
const checkYoutubeStatus = () => {
    
    (async() => {
        while(typeof $hiddenYtObject == 'undefined')
        
        await new Promise(resolve => setTimeout(resolve, 1000));
            ytLiveTimer = setInterval(function() {
            // Get getVideoUrl() from $hiddenYtObject and set it to videoUrl.  
            // videoUrl = https://www.youtube.com/watch?v=live_stream (not live) or https://www.youtube.com/watch?v=WlvptoPIOLk (live)
            let videoUrl = $hiddenYtObject.getVideoUrl();
            // Isolate the video ID. This will be at the end of the videoURL. "live_stream" or "WlvptoPIOLk"
            let videoId = videoUrl.match(/[?&]v=([^&]+)/)[1];
            // If videoId = "live_stream", it is not live
            if(videoId == 'live_stream') {
                clearInterval(ytLiveTimer);
                document.querySelector('#embedvidio-widget-' + $widgetId + ' .youtube-hidden-embed-' + $widgetId).src = document.querySelector('#embedvidio-widget-' + $widgetId + ' .youtube-hidden-embed-' + $widgetId).src;
            // If videoId = "WlvptoPIOLk", it is live
            } else {
                clearInterval(ytLiveTimer);
                // Set liveState to true / Update API model live_status to true
                liveState.set(true);
                if($store.live_status != 1){
                    apiLiveStatus = 1;
                    apiLivePermalink = 'https://www.youtube.com/embed/' + videoId;
                    updateApi();
                    //console.log('2nd');
                }
                // Set "currentVideo" to the YouTube embed URL
                currentVideo.set(videoId);
            }

        }, $ytLiveRefresh * 1000);                 
    })();
}

/**
 * Check to see if YT channel is ALREADY live
 ************************************************/ 
function initCheckLive() {
    (async() => {
        while(typeof $hiddenYtObject == 'undefined')
        await new Promise(resolve => setTimeout(resolve, 1000));

        (async() => {
            while(typeof $hiddenYtObject.getVideoUrl == 'undefined')
            await new Promise(resolve => setTimeout(resolve, 1000));

            let videoUrl = $hiddenYtObject.getVideoUrl();
            let videoId = videoUrl.match(/[?&]v=([^&]+)/)[1];
            
            // If channel is live
            if(videoId !== 'live_stream') {
                clearInterval(ytLiveTimer);
                liveState.set(true);
                currentVideo.set(videoId);
                if($store.live_status != 1){
                    apiLiveStatus = 1;
                    apiLivePermalink = 'https://www.youtube.com/embed/' + videoId;
                    updateApi();
                    //console.log('3rd');
                }
            }else{
                //Update API model live_status to false
                if($store.live_status != 0){
                    apiLiveStatus = 0;
                    apiLivePermalink = 'null';
                    updateApi();
                    //console.log('4th');
                }
            }
        })();
    })();
}

/**
 * Update API widget model live_status
 ************************************************/ 
function updateApi() {
    (async() => {
        const data = new FormData();
        //append data to "formData"
        data.append('widget_id', $widgetId);
        data.append('yt_channel', $store.youtube_channel_id);
        data.append('yt_channel_title', $store.youtube_channel_title);
        data.append('live_status', apiLiveStatus);
        data.append('live_permalink', apiLivePermalink);
        data.append('origin', location.origin);
        data.append('_method', 'PUT');
        //send data to server
        await axios.post($appUrl + '/api/update-youtube-live-status/' + $widgetId, data)
    })();
}
</script>


<!--
// HTML
---------------->
<div>
{#if $liveState}
    <LiveVideo 
        widget={$widgetId}
        branding={$store.branding}
    />
{:else}
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.youtube_meta.thumbnail}
        headerTitle={$store.youtube_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.youtube_channel_id}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
    <Placeholder 
        placeholderVideoPermalink={$store.placeholder_permalink}
        lastVideoPlaceholder={$store.last_video_placeholder}
        placeholderImg={$store.placeholder_image} 
        channelTitle={$store.channel_title} 
        branding={$store.branding}
    />
{/if}
</div>

<!-- This is here in the background in order to set the video player onMount-->
<div style="display:none;">
    <iframe 
        id="youtube-hidden-embed-{ $widgetId }"
        class="youtube-hidden-embed-{ $widgetId }"
        src="https://www.youtube.com/embed/live_stream?channel={ $store.youtube_channel_id }&enablejsapi=1&version=3&origin={ location.origin }" 
        title="">
    </iframe>
</div>