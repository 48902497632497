import App from './App.svelte';

const app = new App({
	// target: document.body,
	target: document.getElementById(document.currentScript.id.replace('-widget', '')),
	props: {
		widget: document.currentScript.id.replace('-widget', '')
	}
});

export default app;