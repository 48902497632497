<!--
// Scripts
---------------->
<script>
import Branding from "../../../../../components/Branding.svelte";
export let placeholderVideoPermalink;
export let lastVideoPlaceholder;
export let placeholderImg;
export let channelTitle;
export let branding;
</script>

<!--
// HTML
---------------->
<div>
{#if lastVideoPlaceholder == 1}
<iframe class="ev-video"
    src="{placeholderVideoPermalink}"
    title=""
    frameborder="0"
    allow=""
    allowfullscreen
></iframe>
{:else}
<div class="ev-placeholder-image">
    <img src="{placeholderImg}" alt="{channelTitle} Live">
</div>
{/if}
{#if branding == 1}
    <Branding />
{/if}
</div>


<!--
// Styles
---------------->
<style>
.ev-placeholder-image img {
    width: 100%;
    height: auto;
}
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

</style>