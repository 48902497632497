<!--
// Scripts
---------------->
<script>
import Branding from "../../../../../components/Branding.svelte";
let hostname = window.location.hostname;
export let placeholderVideoPermalink;
export let lastVideoPlaceholder;
export let placeholderImg;
export let branding;
</script>


<!--
// HTML
---------------->
<div>
{#if lastVideoPlaceholder == 1}
<div>
    <iframe
        class="ev-video"
        src="https://player.twitch.tv/?video=v{placeholderVideoPermalink}&parent={hostname}&autoplay=false"
        allowfullscreen
        title="test">
    </iframe>
</div>
{:else}
<div class="ev-placeholder-image">
    <img src="{placeholderImg}" alt="">
</div>
{/if}
{#if branding == 1}
    <Branding />
{/if}
</div>


<!--
// Styles
---------------->   
<style>
.ev-placeholder-image img {
    width: 100%;
    height: auto;
}
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
</style>