<!--
// Scripts
---------------->
<script>
function delay(millisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, millisec);
    })
}    
</script>


<!--
// HTML
---------------->
{#await delay(250)}
<div></div>
{:then}
<div class="ev-loading">
    <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <circle fill="#2463eb" stroke="none" cx="6" cy="50" r="6">
        <animateTransform 
            attributeName="transform" 
            dur="1s" 
            type="translate" 
            values="0 15 ; 0 -15; 0 15" 
            repeatCount="indefinite" 
            begin="0.1"/>
        </circle>
        <circle fill="#2463eb" stroke="none" cx="30" cy="50" r="6">
        <animateTransform 
            attributeName="transform" 
            dur="1s" 
            type="translate" 
            values="0 10 ; 0 -10; 0 10" 
            repeatCount="indefinite" 
            begin="0.2"/>
        </circle>
        <circle fill="#2463eb" stroke="none" cx="54" cy="50" r="6">
        <animateTransform 
            attributeName="transform" 
            dur="1s" 
            type="translate" 
            values="0 5 ; 0 -5; 0 5" 
            repeatCount="indefinite" 
            begin="0.3"/>
        </circle>
    </svg>
</div>
{/await}

<!--
// Styles
---------------->
<style>
.ev-loading{
    position: absolute;
    margin: 50px auto;
    max-width: 140px;
    top: 0;
    right: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 9999;
}
</style>