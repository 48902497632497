<!--
// Scripts
---------------->
<script>

</script>

<!--
// HTML
---------------->
<div class="ev-branding">
    <div class="ev-widget-powered">
        <a href="https://www.embedvid.io" title="EmbedVidio | Auto-Embed Social Media Video and Live Video Widgets For Any Website." target="_blank" class="ev-widget-link">
        Widget by
        <svg class="ev-widget-logo" version="1.0" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M798 5108 c-2 -6 -35 -15 -73 -19 -38 -5 -73 -13 -79 -19 -5 -5 -16
                -10 -24 -10 -24 0 -172 -69 -172 -80 0 -4 -11 -11 -24 -14 -13 -3 -43 -24 -66
                -46 -23 -22 -45 -40 -49 -40 -9 0 -71 -63 -71 -72 0 -4 -18 -25 -40 -48 -22
                -23 -43 -53 -46 -66 -3 -13 -10 -24 -14 -24 -11 0 -80 -148 -80 -172 0 -8 -5
                -19 -10 -24 -6 -6 -14 -41 -19 -79 -4 -38 -13 -71 -19 -73 -17 -5 -17 -3519 0
                -3524 6 -2 15 -35 19 -73 5 -38 13 -73 19 -79 5 -5 10 -16 10 -24 0 -24 69
                -172 80 -172 4 0 11 -11 14 -24 3 -13 24 -43 46 -66 22 -23 40 -45 40 -49 0
                -9 63 -71 72 -71 4 0 25 -18 48 -40 23 -22 53 -43 66 -46 13 -3 24 -10 24 -14
                0 -11 148 -80 172 -80 8 0 19 -5 24 -10 6 -6 41 -14 79 -19 38 -4 71 -13 73
                -19 5 -17 3519 -17 3524 0 2 6 35 15 73 19 38 5 73 13 79 19 5 5 16 10 24 10
                24 0 172 69 172 80 0 4 11 11 24 14 13 3 43 24 66 46 23 22 45 40 49 40 9 0
                71 63 71 72 0 4 18 25 40 48 22 23 43 53 46 66 3 13 10 24 14 24 11 0 80 148
                80 172 0 8 5 19 10 24 6 6 14 41 19 79 4 38 13 71 19 73 17 5 17 3519 0 3524
                -6 2 -15 35 -19 73 -5 38 -13 73 -19 79 -5 5 -10 16 -10 24 0 24 -69 172 -80
                172 -4 0 -11 11 -14 24 -3 13 -24 43 -46 66 -22 23 -40 45 -40 49 0 9 -63 71
                -72 71 -4 0 -25 18 -48 40 -23 22 -53 43 -66 46 -13 3 -24 10 -24 14 0 11
                -148 80 -172 80 -8 0 -19 5 -24 10 -6 6 -41 14 -79 19 -38 4 -71 13 -73 19 -5
                17 -3519 17 -3524 0z m1336 -1413 c8 -8 24 -15 36 -15 11 0 23 -7 26 -15 4 -8
                12 -15 20 -15 8 0 14 -7 14 -15 0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -9 7
                -18 15 -21 8 -4 15 -16 15 -28 0 -11 7 -30 15 -40 21 -28 21 -179 0 -200 -8
                -8 -15 -26 -15 -40 0 -16 -6 -26 -15 -26 -8 0 -15 -7 -15 -15 0 -21 -118 -135
                -141 -135 -5 0 -9 -7 -9 -15 0 -8 -6 -15 -14 -15 -8 0 -16 -7 -20 -15 -3 -8
                -15 -15 -26 -15 -11 0 -23 -7 -26 -15 -4 -8 -12 -15 -20 -15 -8 0 -14 -7 -14
                -15 0 -8 -8 -15 -19 -15 -10 0 -24 -7 -31 -15 -7 -8 -19 -15 -26 -15 -8 0 -14
                -7 -14 -15 0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15 -9 0 -5 -20 -21 -45 -35 -25
                -15 -45 -31 -45 -36 0 -6 -6 -10 -14 -10 -8 0 -16 -7 -20 -15 -3 -8 -12 -15
                -21 -15 -8 0 -15 -5 -15 -10 0 -6 -11 -15 -25 -20 -14 -5 -25 -14 -25 -20 0
                -5 -7 -10 -15 -10 -8 0 -15 -7 -15 -15 0 -8 -6 -15 -13 -15 -7 0 -20 -8 -28
                -17 -9 -10 -26 -25 -38 -33 -13 -8 -33 -23 -44 -33 -12 -11 -33 -27 -46 -36
                -24 -18 -24 -18 -4 -37 11 -10 30 -26 43 -34 12 -8 28 -19 34 -25 6 -5 30 -23
                54 -39 24 -17 53 -40 65 -53 12 -12 27 -23 32 -23 6 0 24 -12 41 -27 37 -33
                61 -50 92 -63 12 -5 22 -14 22 -20 0 -5 7 -10 15 -10 8 0 15 -6 15 -14 0 -7 8
                -17 18 -20 34 -13 72 -37 72 -47 0 -5 7 -9 15 -9 8 0 15 -7 15 -15 0 -8 7 -15
                15 -15 8 0 15 -4 15 -9 0 -10 38 -34 73 -47 9 -3 17 -13 17 -20 0 -8 4 -14 9
                -14 6 0 25 -14 43 -30 18 -17 38 -30 46 -30 7 0 15 -7 18 -15 4 -8 12 -15 20
                -15 8 0 14 -7 14 -15 0 -8 7 -15 15 -15 9 0 15 -9 15 -25 0 -14 7 -28 15 -31
                8 -4 15 -18 15 -33 0 -14 7 -39 15 -55 19 -35 19 -73 0 -106 -8 -14 -14 -38
                -15 -53 0 -15 -7 -30 -15 -33 -8 -4 -15 -10 -15 -15 0 -15 -62 -79 -76 -79 -6
                0 -14 -7 -18 -15 -3 -8 -14 -15 -24 -15 -10 0 -34 -7 -51 -16 -36 -18 -123
                -14 -129 5 -2 6 -19 11 -37 11 -19 0 -40 7 -49 15 -8 8 -21 15 -29 15 -8 0
                -20 7 -27 15 -7 8 -19 15 -26 15 -8 0 -14 4 -14 9 0 4 -19 21 -42 37 -24 16
                -44 32 -46 37 -2 4 -10 7 -18 7 -8 0 -17 7 -20 15 -4 8 -12 15 -20 15 -8 0
                -14 6 -14 14 0 8 -11 17 -25 20 -14 4 -25 11 -25 16 0 6 -7 10 -15 10 -9 0
                -18 7 -21 15 -4 8 -12 15 -20 15 -8 0 -14 7 -14 15 0 8 -11 17 -25 21 -14 3
                -25 10 -25 15 0 5 -7 12 -16 16 -9 3 -30 19 -46 35 -16 15 -35 28 -43 28 -8 0
                -15 5 -15 10 0 6 -7 13 -16 17 -9 3 -30 19 -46 35 -16 15 -35 28 -42 28 -8 0
                -16 7 -20 15 -3 8 -10 15 -16 15 -6 0 -21 10 -33 23 -12 12 -40 35 -63 50 -22
                15 -49 35 -60 45 -32 28 -83 62 -93 62 -5 0 -11 7 -15 15 -3 8 -12 15 -21 15
                -8 0 -15 4 -15 9 0 5 -8 12 -17 15 -10 4 -32 19 -49 34 -18 15 -42 34 -55 42
                -37 25 -79 70 -79 85 0 8 -5 15 -11 15 -5 0 -15 14 -20 30 -6 17 -15 30 -20
                30 -5 0 -9 41 -9 91 0 67 4 94 15 105 8 9 15 24 15 34 0 10 7 20 15 24 8 3 15
                11 15 18 0 7 16 27 36 43 20 17 43 36 50 43 7 6 17 12 23 12 6 0 11 4 11 9 0
                4 19 21 43 37 23 16 52 39 64 51 12 13 26 23 30 23 5 0 16 8 24 18 9 9 26 24
                39 32 13 8 36 27 52 43 16 15 33 27 39 27 5 0 12 7 15 15 4 8 11 15 18 15 6 0
                25 14 43 30 18 17 35 30 38 30 3 0 19 14 35 30 16 17 33 30 37 30 4 0 22 14
                40 30 18 17 34 30 36 30 6 0 59 44 94 78 13 12 26 22 28 22 3 0 13 8 23 18 10
                9 40 35 67 57 27 22 57 48 67 58 10 9 23 17 28 17 6 0 10 7 10 15 0 8 6 15 14
                15 8 0 16 7 20 15 3 8 12 15 20 15 8 0 16 3 18 8 5 11 66 52 78 52 5 0 10 4
                10 10 0 5 18 13 41 16 22 4 43 13 46 20 8 21 156 19 177 -1z m1080 0 c8 -8 26
                -15 40 -15 14 0 26 -5 26 -11 0 -5 14 -15 30 -20 17 -6 30 -15 30 -20 0 -5 7
                -9 15 -9 9 0 18 -7 21 -15 4 -8 12 -15 20 -15 8 0 14 -7 14 -15 0 -8 5 -15 10
                -15 14 0 110 -72 110 -82 0 -4 7 -8 15 -8 8 0 15 -7 15 -15 0 -8 5 -15 11 -15
                12 0 81 -51 112 -82 10 -10 30 -26 45 -35 15 -10 31 -23 35 -30 4 -7 15 -13
                22 -13 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8 0 15 -3 15 -7 0 -5 17 -19 37
                -33 21 -14 50 -37 65 -52 15 -16 30 -28 33 -28 4 0 12 -6 19 -12 8 -7 24 -20
                36 -28 13 -8 30 -23 39 -32 8 -10 19 -18 23 -18 4 0 20 -12 36 -27 16 -16 39
                -35 52 -43 13 -8 30 -23 39 -32 8 -10 19 -18 23 -18 4 0 21 -13 38 -30 17 -16
                37 -30 46 -30 8 0 17 -7 20 -15 4 -8 10 -15 15 -15 11 0 109 -98 109 -109 0
                -5 7 -11 15 -15 9 -3 15 -18 15 -35 0 -16 7 -36 15 -45 21 -20 21 -139 0 -156
                -8 -7 -15 -23 -15 -36 0 -15 -6 -24 -15 -24 -8 0 -15 -7 -15 -15 0 -9 -7 -18
                -15 -21 -8 -4 -15 -10 -15 -15 0 -10 -37 -49 -46 -49 -4 0 -17 -11 -30 -25
                -13 -14 -28 -25 -34 -25 -6 0 -13 -6 -16 -14 -3 -8 -16 -17 -30 -20 -13 -4
                -24 -11 -24 -16 0 -6 -7 -10 -15 -10 -9 0 -18 -7 -21 -15 -4 -8 -12 -15 -19
                -15 -6 0 -23 -9 -36 -21 -13 -11 -52 -42 -86 -67 -35 -25 -63 -49 -63 -54 0
                -4 -7 -8 -15 -8 -9 0 -18 -7 -21 -15 -4 -8 -12 -15 -20 -15 -7 0 -27 -14 -44
                -30 -17 -17 -35 -30 -41 -30 -5 0 -12 -7 -15 -15 -4 -8 -12 -15 -20 -15 -7 0
                -26 -13 -42 -28 -16 -16 -37 -32 -46 -35 -9 -4 -16 -11 -16 -17 0 -5 -7 -10
                -15 -10 -9 0 -31 -16 -49 -35 -19 -19 -39 -35 -45 -35 -6 0 -11 -4 -11 -10 0
                -5 -11 -12 -25 -16 -14 -3 -25 -12 -25 -20 0 -8 -7 -14 -15 -14 -8 0 -15 -5
                -15 -10 0 -6 -8 -14 -17 -17 -19 -7 -32 -17 -64 -45 -10 -10 -21 -18 -23 -18
                -3 0 -18 -12 -33 -26 -15 -14 -37 -30 -48 -35 -27 -12 -59 -32 -66 -42 -3 -4
                -23 -10 -45 -14 -21 -3 -42 -11 -46 -17 -4 -5 -35 -12 -70 -14 -51 -3 -72 0
                -103 17 -22 12 -45 21 -52 21 -7 0 -13 5 -13 10 0 6 -7 10 -15 10 -8 0 -15 7
                -15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 9 -7 18 -15 21 -8 4 -15 18 -15
                34 0 15 -7 33 -15 40 -20 17 -21 129 0 156 8 10 15 29 15 41 0 38 55 123 80
                123 6 0 10 7 10 15 0 8 7 15 15 15 8 0 15 5 15 11 0 5 14 15 30 20 17 6 30 15
                30 20 0 5 7 9 15 9 8 0 15 7 15 15 0 8 7 15 15 15 8 0 15 4 15 9 0 10 38 34
                73 47 9 3 17 13 17 20 0 8 7 14 15 14 8 0 15 4 15 9 0 10 38 34 73 47 9 3 17
                13 17 20 0 8 7 14 15 14 8 0 15 7 15 15 0 8 8 15 19 15 10 0 24 7 31 15 7 8
                19 15 26 15 8 0 14 7 14 15 0 8 7 15 15 15 9 0 18 7 21 15 4 8 11 15 18 15 6
                0 25 14 43 30 18 17 36 30 42 30 5 0 14 6 18 13 4 7 25 23 46 36 20 12 37 27
                37 32 0 5 6 9 13 9 7 0 21 10 31 21 18 21 17 22 -10 46 -16 14 -40 33 -54 41
                -14 9 -29 22 -33 29 -4 7 -13 13 -20 13 -7 1 -23 14 -37 30 -13 17 -29 30 -36
                30 -7 0 -14 7 -18 15 -3 8 -12 15 -20 15 -7 0 -16 7 -20 15 -3 8 -12 15 -21
                15 -8 0 -15 6 -15 14 0 7 -8 17 -17 20 -35 13 -73 37 -73 47 0 5 -7 9 -15 9
                -8 0 -15 7 -15 15 0 8 -6 15 -14 15 -8 0 -16 7 -20 15 -3 8 -15 15 -26 15 -11
                0 -23 7 -26 15 -4 8 -12 15 -20 15 -8 0 -14 4 -14 9 0 10 -38 34 -72 47 -10 3
                -18 13 -18 20 0 8 -7 14 -15 14 -8 0 -15 4 -15 9 0 10 -38 34 -72 47 -10 3
                -18 13 -18 20 0 8 -7 14 -15 14 -8 0 -15 7 -15 15 0 8 -6 15 -12 15 -12 0 -58
                87 -58 110 0 5 -4 10 -10 10 -6 0 -10 42 -10 105 0 85 3 107 15 111 9 4 15 19
                15 35 0 19 5 29 15 29 8 0 15 5 15 10 0 6 9 19 20 30 11 11 25 20 30 20 6 0
                10 7 10 15 0 8 8 15 19 15 10 0 24 7 31 15 9 11 33 15 91 15 56 0 82 -4 93
                -15z"/>
            </g>
        </svg>
        <span class="ev-hover">EmbedVidio</span>
        </a>
    </div>
</div>

<!--
// Styles
---------------->
<style>
.ev-branding {
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;
    color: #9ca0ac !important;
    background-color: transparent;
    padding: 5px;
}
.ev-widget-powered {
    display: inline-flex;
    align-items: center;
}
.ev-widget-link {
    display: inline-flex;
    text-decoration: none !important;
    color: #8ea4bd !important;
    align-items: center;
}
.ev-widget-link:hover {
    color: #8ea4bd !important;
}
.ev-widget-logo { 
    width: 14px;
    height: 14px;
    margin-right: 4px;
    margin-left: 8px;
    margin-bottom: 1px;
    fill: #8ea4bd;
}
.ev-widget-logo:hover {
    fill:#0169ff !important;
}
.ev-hover:hover {
    color:#0169ff !important;
}
</style>