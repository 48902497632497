<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import LiveVideo from './LiveVideo.svelte';
import Placeholder from './Placeholder.svelte';
import Header from "../../../../../providers/facebook/templates/components/Header.svelte"
import { store, widgetId, liveState, currentVideo, pushedLiveOrientation, pusherAppKey } from '../../../../../stores/widget.js';
import Pusher from 'pusher-js';

onMount(() => {
    if($store.live_status == 0) {
        let pusher = new Pusher($pusherAppKey, {
            cluster: 'us3'
        });

        let notifications = pusher.subscribe('embedvidio-status-' + $widgetId);
            notifications.bind('online-status', function(result) {
            liveState.set(true);
            currentVideo.set(result.live_permalink);
            pushedLiveOrientation.set(result.live_orientation);
            pusher.disconnect();
        });     
              
    } else {
        currentVideo.set($store.live_permalink);
    }
})
</script>


<!--
// HTML
---------------->
<div>
{#if $store.live_status == 1 || $liveState}
    <!-- Live component -->
    <LiveVideo
        widget={$widgetId}
        liveVideo={$currentVideo}
        pushedLiveOrientation={$pushedLiveOrientation}
        setLiveOrientation={$store.live_orientation}
        branding={$store.branding}
    />
{:else}
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.facebook_meta.picture}
        headerTitle={$store.facebook_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.facebook_page_id}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}

    <!-- Placeholder component -->
    <Placeholder 
        widget={$widgetId}
        placeholderVideoPermalink={$store.placeholder_permalink}
        lastVideoPlaceholder={$store.last_video_placeholder}
        placeholderImg={$store.placeholder_image} 
        placeholderVideoOrientation={$store.placeholder_video_orientation}
        branding={$store.branding}
    />
{/if}
</div>


<!--
// Styles
---------------->