<!--
// Javascript
---------------->
<script>
import Header from "../../../../../providers/youtube/templates/components/Header.svelte"
import SocialLogo from "../../../../../components/logos/Youtube.svelte"
import { store, videos } from '../../../../../stores/widget.js'
</script>


<!--
// HTML
---------------->
<div>
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.youtube_meta.thumbnail}
        headerTitle={$store.youtube_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.youtube_channel_id}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
	<!-- Cards -->
    <div class="ev-cards">
        {#each $videos as {picture, title, description, channel_title, published_time}, i}
        <div class="ev-card" id="{i}" on:click>
            <!-- Meta data -->
            <div class="ev-meta">
                <div class="ev-details">
                    <div class="ev-logo">
                        <SocialLogo />
                    </div>
                    <div class="ev-title">{title ? title : channel_title}</div>
                    <div class="ev-date">{published_time}</div>
                </div>
            </div>
            <!-- Thumbnail image -->
            <div class="ev-thumbnail">
                <img src="{picture}" alt="{title}"/>
            </div>
            <!-- Description -->
            <div class="ev-description ev-text-clamp">
                {description ? description : '...'}
            </div>
        </div>
        {/each}
    </div>

</div>


<!--
// Styles
---------------->
<style>
.ev-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	/* max-width: 1200px; */
  	column-gap: 1rem;
	/* margin: 0 auto; */
}
.ev-card {
	position: relative;
    width: 150px;
    margin: 0 1.5rem 1.5rem 0;
    display: inline-block;
    width: 100%;
    border: solid 1px #eef1f7;
	background-color: #FFFFFF;
    padding: 15px;
    border-radius: 10px;
	cursor: pointer;
}
.ev-card::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: ' ';
}
.ev-card:hover{
    opacity: 1;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    transition: all .2s ease;
}
.ev-meta{
    align-items: center;
    display: flex;
    position: relative;
}
.ev-details {
    display: inline-block;
    margin-right: 10px;
    overflow: hidden;
    width: 100%;
}
.ev-title {
    color: rgb(17, 24, 39);
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
    text-align: left;
}
.ev-date{
    margin-top: -2px;
    color: #9ca0ac;
    font-weight:300;
    font-size: small;
    text-align: left;
}
.ev-logo{
    margin-top: 8px;
    float: right;
}
.ev-thumbnail img {
	height:190px;
	object-fit: cover;
	width: 100%;
	border-radius: 10px;
	margin-top:10px;
	border: 1px solid #f5f5f5;
}
.ev-description {
    margin: 20px 0;
    padding: 0 5px;
    color: rgb(107, 114, 128);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
    text-align: left;
}
.ev-text-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
@media (max-width:1200px){
    .ev-thumbnail img {
        max-height:152px;
    }
}
</style>