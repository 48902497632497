<!--
// Javascript
---------------->
<script>
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

export let permalink;
export let videoId;
export let userName;
export let title;
export let description;
export let publishedAt;

let hostname = window.location.hostname;
export let showHideImg = true;
export let showHideVideo = false;

function addVideo() {
    showHideImg = true;
    showHideVideo = false;
}
</script>

<!--
// HTML
---------------->
<div class="ev-container">

    <div class="">
        <a href="#prev" on:click={() => dispatch('prevClick')} on:click={addVideo} class="ev-prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg> 
        </a>
    </div>

    <div class="ev-slide">
        <div class="ev-content">
            <div>
                <iframe
                    class="ev-video"
                    src="https://player.twitch.tv/?video=v{videoId}&parent={hostname}&autoplay=true"
                    allowfullscreen
                    title="{title}">
                </iframe>
            </div>
            
        </div>
        <div class="ev-meta">
            <div class="ev-logo">
                <a href="{permalink}" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 300 300">
                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#65459B" d="M215.2 260.8h-58.7L117.4 300H78.3v-39.2H6.6V52.2L26.1 0h267.3v182.6l-78.2 78.2zm52.2-91.2V26.1H52.2v189.1h58.7v39.1l39.1-39.1h71.7l45.7-45.6z"/>
                        <path fill="#65459B" d="M195.6 78.3v78.3h26.1V78.3h-26.1zm-71.7 78.2H150V78.3h-26.1v78.2z"/>
                    </svg>
                </a>
            </div>
            <div>
                <a class="ev-name" href="{permalink}" target="_blank">
                    @{userName}
                </a>
                <div class="ev-date">{publishedAt}</div>
            </div>
            <div>
                <div class="ev-title">{title ? title : userName}</div>
                <div class="ev-description">
                    {description ? description : '...'}
                </div>
            </div>
        </div>   
    </div>

    <div class="">
        <a href="#next" on:click={() => dispatch('nextClick')} on:click={addVideo} class="ev-next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg> 
        </a>
    </div>

</div>


<!--
// Styles
---------------->
<style>
.ev-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
.ev-slide {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1rem;
    padding:20px;
    background: #ffffff;
    border-radius: 10px;
}
.ev-next,
.ev-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(138, 138, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    margin:0px 20px;
    opacity:.4;
    padding: 4px;
}
.ev-next:hover,
.ev-prev:hover {
    background-color: rgb(255, 255, 255);
    opacity:.8;
}
.ev-content {
    display: grid;
}
.ev-meta{
    min-width: 330px;
    padding: 0px 10px;
    text-align: left;
}
.ev-name{
    color: rgb(37, 99,235);
    font-size:larger;
    font-weight: 600;
}
.ev-name:hover{
    color: rgb(63, 115, 228);
}
.ev-date{
    margin-top: -2px;
    margin-left:3px;
    color: #9ca0ac;
    font-weight:300;
    font-size: small;
}
.ev-logo{
    float:right
}
.ev-title {
    color: rgb(17, 24, 39);
    font-size: 16px;
    font-weight: 600;
    margin:20px 0px 10px 0px;
}
.ev-description {
    color: rgb(107, 114, 128);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
}
@media only screen and (max-width: 1200px){
    .ev-slide{
        grid-template-columns: 1fr;
        padding:0px;
        background: #ffffff;
    }
    .ev-next,
    .ev-prev{
        display:none;
    }
    .ev-meta{
        padding:20px;
    }
}
</style>