<!--
// Javascript
---------------->
<script>
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

// export let image;
export let videoId;
export let permalink;
export let channelTitle;
export let title;
export let description;
export let publishedTime;
</script>

<!--
// HTML
---------------->
<div class="ev-container">

    <div class="">
        <a href="#prev" on:click={() => dispatch('prevClick')} class="ev-prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </a>
    </div>

    <div class="ev-slide">
        <div class="ev-content">
            <!-- Video -->
            <div>
                <iframe class="ev-video"
                    src="{permalink}?&autoplay=1"
                    title="{title}"
                    frameborder="0"
                    allow=""
                    allowfullscreen
                ></iframe>
            </div>
        </div>
        <div class="ev-meta">
            <div class="ev-logo">
                <a href="https://www.youtube.com/watch?v={videoId}" target="_blank">
                    <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="YouTube logo.">
                        <g clip-path="url(#clip_youtube_logo_background)">
                            <path d="M29.3731 7.79324C29.0281 6.49403 28.0117 5.47096 26.7208 5.12375C24.3813 4.49273 15 4.49272 15 4.49272C15 4.49272 5.61875 4.49273 3.27919 5.12375C1.98846 5.47096 0.971857 6.49403 0.626886 7.79324C0 10.1479 0 15.0609 0 15.0609C0 15.0609 0 19.9738 0.626886 22.3287C0.971857 23.6278 1.98846 24.6508 3.27919 24.9982C5.61875 25.6291 15 25.6291 15 25.6291C15 25.6291 24.3813 25.6291 26.7208 24.9982C28.0117 24.6508 29.0281 23.6278 29.3731 22.3287C30 19.9738 30 15.0609 30 15.0609C30 15.0609 30 10.1479 29.3731 7.79324Z" fill="#FF0000"></path>
                            <path d="M11.9319 19.5215L19.7728 15.0611L11.9319 10.6003V19.5215Z" fill="white"></path>
                        </g>
                        <defs>
                            <clipPath id="clip_youtube_logo_background">
                                <rect width="30" height="30" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
            <div>
                <a class="ev-name ev-text-clamp" href="https://www.youtube.com/watch?v={videoId}" target="_blank">
                    {channelTitle}
                </a>
                <div class="ev-date">{publishedTime}</div>
            </div>
            <div>
                <div class="ev-title">{title ? title : channelTitle}</div>
                <div class="ev-description">
                    {description ? description : '...'}
                </div>
            </div>
        </div>   
    </div>

    <div class="">
        <a href="#next" on:click={() => dispatch('nextClick')} class="ev-next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg> 
        </a>
    </div>

</div>


<!--
// Styles
---------------->
<style>
.ev-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: inherit;
}
.ev-slide {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1rem;
    padding:20px;
    background: #ffffff;
    border-radius: 10px;
    overflow-y: hidden;
}
.ev-next,
.ev-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(138, 138, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    margin: 0px 20px;
    opacity: .4;
    padding: 4px;
}
.ev-next:hover,
.ev-prev:hover {
    background-color: rgb(255, 255, 255);
    opacity:.8;
}
.ev-content {
    display: grid;
}
.ev-meta{
    min-width: 330px;
    padding: 0px 10px;
    text-align: left;
}
.ev-name{
    color: rgb(37, 99,235);
    font-size:larger;
    font-weight: 600;
}
.ev-name:hover{
    color: rgb(63, 115, 228);
}
.ev-date{
    margin-top: -2px;
    margin-left:3px;
    color: #9ca0ac;
	font-weight:300;
	font-size: small;
}
.ev-logo{
    float:right
}
.ev-title {
    color: rgb(17, 24, 39);
    font-size: 16px;
    font-weight: 600;
    margin:20px 0px 10px 0px;
}
.ev-description {
    color: rgb(107, 114, 128);
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
}
.ev-text-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media only screen and (max-width: 1200px){
    .ev-slide{
        grid-template-columns: 1fr;
        padding:0px;
        background: #ffffff;
    }
    .ev-next,
    .ev-prev{
        display:none;
    }
    .ev-meta{
        padding:20px;
    }
}
</style>