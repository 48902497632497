<!--
// Scripts
---------------->
<script>
export let videoId;
export let title;
let hostname = window.location.hostname;
</script>


<!--
// HTML
---------------->
<iframe
    class="ev-video"
    src="https://player.twitch.tv/?video=v{videoId}&parent={hostname}&autoplay=true"
    allowfullscreen
    title="{title}">
</iframe>


<!--
// Styles
---------------->
<style>
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
</style>