<!--
// Scripts
---------------->
<script>
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();
export let thumbImg;
export let title;
export let selected;
export let description;
export let pageName;
</script>


<!--
// HTML
---------------->
<div class="ev-list" class:ev-active={selected}>
	<div class="ev-card" on:click={() => dispatch('getVideo')} on:click>
		<div class="ev-thumbnail">
			<img src={thumbImg} alt="{title}"/>
		</div>
		<div class="ev-details">
			<div class="ev-title">{title ? title : pageName}</div>
			<div class="ev-description">{description ? description : '...'}</div>
		</div>
	</div>
</div>


<!--
// Styles
---------------->
<style>
.ev-list{
	display: grid;
	padding:10px;
	background-color: #eee;
	cursor: pointer;
	border-radius: 5px;
	overflow: hidden;
	scroll-behavior: smooth;
	margin-bottom: 10px;
	/* scroll-snap-align:start;  */
}
.ev-card{
	display:flex;
}
.ev-list:last-child{
	margin-bottom: 0px;
}
.ev-thumbnail{
    margin-right: 10px;
}
.ev-thumbnail img{
    border-radius: 5px;
    min-width: 100px !important;
    height: 56px !important;
    object-fit: cover;
}
.ev-details{
	min-width: 0;
    text-align: left;
}
.ev-title {
	font-size:14px;
	color: rgb(17, 24, 39);
	font-weight: 500;
	margin-top: -3px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
.ev-description{
	font-size:12px;
	color: rgb(107, 114, 128);
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.ev-active {
	background-color: rgb(207, 204, 204);
}
@media (max-width:1200px){
	.ev-list{
		flex-flow: column;
		gap:10px;
	}
}
</style>