<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import Branding from "../../../../../components/Branding.svelte";
import LiveVideo from '../liveonly/LiveVideo.svelte';
import Wall from '../../archive/wall/App.svelte';
import Grid from '../../archive/grid/App.svelte';
import Collage from '../../archive/collage/App.svelte';
import Slider from '../../archive/slider/App.svelte';
import Carousel from '../../archive/carousel/App.svelte';
import { store, widgetId, liveState, currentVideo, pushedLiveOrientation, pusherAppKey } from '../../../../../stores/widget.js';

onMount(() => {

    if($store.live_status == 0) {
        let pusher = new Pusher($pusherAppKey, {
            cluster: 'us3'
        });

        let notifications = pusher.subscribe('embedvidio-status-' + $widgetId);
        notifications.bind('online-status', function(result) {
            liveState.set(true);
            currentVideo.set(result.live_permalink);
            pushedLiveOrientation.set(result.live_orientation);
            pusher.disconnect();
        });
        
        // (async() => {
        //     while(typeof $store == 'undefined')
        //     await new Promise(resolve => setTimeout(resolve, 1000));
        //     currentVideo.set($store.placeholder_permalink);
        // })();

    } else {
        currentVideo.set($store.live_permalink);
    }

})
</script>


<!--
// HTML
---------------->
{#if $store.live_status == 1 || $liveState}
<div>
    <LiveVideo
        widget={widgetId}
        liveVideo={$currentVideo}
        pushedLiveOrientation={$pushedLiveOrientation}
        setLiveOrientation={$store.live_orientation}
    />
</div>
{#if $store.branding == 1}
    <Branding />
{/if}
{:else}
<div>
    {#if $store.live_plus_layout == 'wall'}
        <Wall />
    {/if}
    {#if $store.live_plus_layout == 'grid'}
        <Grid />
    {/if}
    {#if $store.live_plus_layout == 'collage'}
        <Collage />
    {/if}
    {#if $store.live_plus_layout == 'slider'}
        <Slider />
    {/if}
    {#if $store.live_plus_layout == 'carousel'}
        <Carousel />
    {/if}
</div>
{/if}