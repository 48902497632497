import { writable, readable } from 'svelte/store';
export const widgetId = writable()
export const videos = writable()
export const store = writable(new Promise(() => {}))

export const liveVideo = writable()
export const currentVideo = writable()
export const liveState = writable(false)
export const pushedLiveOrientation = writable(null)

export const provider = writable()
export const providerObject = writable()
export const hiddenYtObject = writable()

const jsFileUrl = document.currentScript.src;

const url = new URL(jsFileUrl);

// Configurable Variables
let apiHost;
let appHost;
// Local URLs
if(url.host == 'localhost:8080') {
apiHost = 'https://dev.embedvid.io'
appHost = 'http://localhost:8080'
// Dev URLs
}else if(url.host == 'dev.embedvid.io'){
apiHost = 'https://dev.embedvid.io'
appHost = 'https://dev.embedvid.io'
// Prod URLs 
}else if(url.host == 'www.embedvid.io'){
apiHost = 'https://www.embedvid.io'
appHost = 'https://www.embedvid.io'
}
export const apiUrl = readable(apiHost) // API URL
export const appUrl = readable(appHost) // App URL

export const ytLiveRefresh = readable(30) // YouTube Refresh Timer
export const pusherAppKey = readable('ac8a44276354ad566ead') // Pusher App Key


function showloader(millisec) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, millisec);
    })
}

export function getData(url) {
    
    const load = async () => {
        const response = await fetch(url);
        await showloader(1500);
        const data = await response.json();
        provider.set(data.provider)
        store.set(data)
        videos.set(data[data.provider + '_videos'])
    }
        load()
        return store;
}

// async function fetchWithTimeout(resource, options = {}) {
//     const { timeout = 8000 } = options;
//     const controller = new AbortController();
//     const id = setTimeout(() => controller.abort(), timeout);
//     const response = await fetch(resource, {
//       signal: controller.signal  
//     });
//     clearTimeout(id);
//     return response;
//   }

// export function getData(url) {
    
//     const load = async () => {
//         try {
//             const response = await fetchWithTimeout(url, {
//                 timeout: 6000
//             });
//             const data = await response.json();
//             provider.set(data.provider)
//             store.set(data)
//             videos.set(data[data.provider + '_videos'])
//         } catch (error) {
//             // Timeouts if the request takes
//             // longer than 6 seconds
//             console.log('Could not return request');
//         }
//     }
//     load()
//     return store;
// }