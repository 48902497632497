<!--
// Scripts
---------------->
<script>
import VideoList from './VideoList.svelte'
import LightBox from '../components/lightbox/Lightbox.svelte'

let modalOpen = false;
let videoShowingIndex = 0;

const handleVideoClick = (e) => {
   videoShowingIndex = Number(e.target.id);
   modalOpen = true;
}
</script>


<!--
// HTML
---------------->
{#if modalOpen}
   <LightBox on:click={() => modalOpen = !modalOpen} {videoShowingIndex} />
{/if}
<VideoList on:click={handleVideoClick}/>


<!--
// Styles
---------------->
<style>
</style>