<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import { currentVideo, providerObject } from '../../../../../stores/widget.js'

export let widget;

onMount(() => {
    (async() => {
        while(typeof YT == 'undefined')
        await new Promise(resolve => setTimeout(resolve, 1000));
        let player = new YT.Player('youtube-embed-' + widget, {
            playerVars: { 'rel': 0, 'autoplay': 1, 'mute': 1, 'enablejsapi': 1, 'origin': location.origin },
        });
        
        providerObject.set(player);

        (async() => {
            while(typeof $providerObject == 'undefined')
                await new Promise(resolve => setTimeout(resolve, 1000));
                (async() => {
                    while(typeof $providerObject.loadVideoById == 'undefined')
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        $providerObject.loadVideoById($currentVideo)
                })();
        })();
    })();    
})
</script>


<!--
// HTML
---------------->
<div class="videoWrapper">
    <div id="youtube-embed-{widget}"></div>
</div>

<!--
// Styles
---------------->
<style>
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
:global(.videoWrapper iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
    