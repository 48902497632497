<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import System from 'svelte-system-info';
import Branding from "../../../../../components/Branding.svelte";
export let widget;
export let lastVideoPlaceholder;
export let placeholderVideoPermalink;
export let placeholderImg;
export let placeholderVideoOrientation;
export let branding;

// Get users operating system
let os = '';
if((System.OSName == 'iOS') || (System.OSName == 'Android')){
    os = 'mobile'
}

// Set videos orientation
let orientation;
if (placeholderVideoOrientation == 'portrait') {
orientation = '576';
} else {
orientation = '';
}
if(os == 'mobile'){
    onMount(() => {
        (async() => {
            while(typeof FB == 'undefined')
            await new Promise(resolve => setTimeout(resolve, 1000));
            setTimeout(function() {
                FB.XFBML.parse(document.getElementById('embedvidio-widget-' + widget))
            }, 400);
        })();
    })
}
</script>

<!--
// HTML
---------------->
<div>
{#if lastVideoPlaceholder == 1}
    <!-- Check users OS, if OS is iOS or Android use JS FB embed -->
    {#if os == 'mobile'}
        <div id="fb-root"  data-href="{placeholderVideoPermalink}"></div>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"></script>
        <div 
            class="fb-video" 
            data-href="{placeholderVideoPermalink}" 
            data-width="auto" 
            data-height={orientation}
            data-show-text="false" 
            data-allowfullscreen="true" 
            data-autoplay="true">
        </div>
    {:else}
        <iframe 
            class="ev-video"
            src="https://www.facebook.com/plugins/video.php?width=1200&height={orientation}&href=https://www.facebook.com{placeholderVideoPermalink}/&show_text=false&t=0" 
            width="auto" 
            height="{orientation}" 
            style="border:none;overflow:hidden" 
            scrolling="no" 
            frameborder="0" 
            allowfullscreen="true" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
            allowFullScreen="true" 
            title="">
        </iframe>
    {/if}

{:else}
<div class="ev-placeholder-image">
    <img src="{placeholderImg}" alt="">
</div>
{/if}
{#if branding == 1}
    <Branding />
{/if}
</div>


<!--
// Styles
---------------->
<style>
.ev-placeholder-image img {
    width: 100%;
    height: auto;
}
.ev-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}
</style>