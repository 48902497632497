<div>
    <div class="ev-container">	
        <div class="ev-body">
            <div class="ev-logo">
                <img src="https://www.embedvid.io/img/logos/embedvidio-logo-text.png" alt="EmbedVidio Logo">
            </div>
            <div class="ev-slogan">
                <div>The easy way to embed live and past videos on your website to grow your audience.</div>
            </div>
            <div class="ev-message">
                <div>Oops! This video widget is no longer available.</div>
            </div>
            <div class="ev-owner">If you are the owner, login to update.</div>
            <a href="https://www.embedvid.io/" class="ev-button">Update Widget</a>
        </div>
        
        <div class="ev-image">
            <img src="https://www.embedvid.io/img/placeholderImages/widget-error-image.png" alt="">
        </div>
    
    </div> 
</div>


<!--
// Styles
---------------->
<style>
.ev-container{
    box-shadow: 0 1px 20px -5px rgb(171, 171, 171) !important;
    background: rgba(255, 255, 255, 0.90) !important;
    text-align: center !important;
    border-radius: 5px !important;
    overflow: hidden !important;
    margin: 5em auto !important;
    width: 700px !important;	
}
.ev-body {
    position: relative !important;
    text-align: left !important;
    overflow: hidden !important;
    padding: 30px 20px !important;
    height: 100% !important;
    float: left !important;
    width: 62% !important;
}
.ev-logo {
    width:175px !important;
    margin-bottom: 10px !important;
}
.ev-slogan {
    color: #344055 !important;
}
.ev-message {
    color: #272e3b !important;
    font-size: 26px !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
}
.ev-button {
    margin-top: 6px !important;
    background-color: #2463eb !important;
    border: none !important;
    color: white !important;
    padding: 10px 28px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    border-radius: 6px !important;
}
.ev-image {
    float: right !important;
    overflow: hidden !important;
    width: 38% !important;
}
img {
    width: 100% !important;
}
</style>