<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import Header from "../../../../../providers/youtube/templates/components/Header.svelte"
import Branding from "../../../../../components/Branding.svelte";
import { store, currentVideo, providerObject } from '../../../../../stores/widget.js'

export let widget;
export let branding;

onMount(() => {
    (async() => {
        while(typeof YT == 'undefined')
        await new Promise(resolve => setTimeout(resolve, 1000));
        let player = new YT.Player('youtube-embed-' + widget, {
            playerVars: { 'rel': 0, 'autoplay': 1, 'mute': 1, 'enablejsapi': 1, 'origin': location.origin },
        });
        
        providerObject.set(player);

        (async() => {
            while(typeof $providerObject == 'undefined')
                await new Promise(resolve => setTimeout(resolve, 1000));
                (async() => {
                    while(typeof $providerObject.loadVideoById == 'undefined')
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        
                        $providerObject.loadVideoById($currentVideo)
                })();
        })();
    })();    
})
</script>


<!--
// HTML
---------------->
<!-- Header -->
{#if $store.header_data.header == 1 }
<Header 
    headerThumbnail={$store.youtube_meta.thumbnail}
    headerTitle={$store.youtube_meta.title}
    headerTitleColor={$store.header_data.header_txt_color}
    headerAccountId={$store.youtube_channel_id}
    customBtn={$store.header_data.custom_btn.active}
    customBtnText={$store.header_data.custom_btn.text}
    customBtnTextColor={$store.header_data.custom_btn.text_color}
    customBtnUrl={$store.header_data.custom_btn.url}
    customBtnColor={$store.header_data.custom_btn.color}
/>
{/if}
<div class="ev-videoWrapper">
    <div id="youtube-embed-{widget}"></div>
</div>

{#if branding == 1}
    <Branding />
{/if}

<!--
// Styles
---------------->
<style>
.ev-videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
:global(.ev-videoWrapper iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
    