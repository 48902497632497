<script>
import { onMount } from 'svelte';
import { fade } from 'svelte/transition';
import Loading from "./components/Loading.svelte";
import NoAccess from "./components/NoAccess.svelte";
import Error from "./components/Error.svelte";
export let widget = '';
import { getData, store, widgetId, provider, apiUrl, appUrl } from "./stores/widget";

const response = getData($apiUrl + "/api/widget/" + widget.replace('embedvidio-', ''));

let Template;
let Templates = {};

onMount(async () => {

	widgetId.set(widget.replace('embedvidio-', ''))


	// Facebook
	Templates['facebook'] = {
		live: (await import('./providers/facebook/templates/live/liveonly/App.svelte')).default,
		live_notice: (await import('./providers/facebook/templates/live/livenotice/App.svelte')).default,
		live_plus: (await import('./providers/facebook/templates/live/liveplus/App.svelte')).default,
		wall: (await import('./providers/facebook/templates/archive/wall/App.svelte')).default,
		grid: (await import('./providers/facebook/templates/archive/grid/App.svelte')).default,
		collage: (await import('./providers/facebook/templates/archive/collage/App.svelte')).default,
		slider: (await import('./providers/facebook/templates/archive/slider/App.svelte')).default,
		carousel: (await import('./providers/facebook/templates/archive/carousel/App.svelte')).default,
	};

	// YouTube
	Templates['youtube'] = {
		live: (await import('./providers/youtube/templates/live/liveonly/App.svelte')).default,
		live_notice: (await import('./providers/youtube/templates/live/livenotice/App.svelte')).default,
		live_plus: (await import('./providers/youtube/templates/live/liveplus/App.svelte')).default,
		wall: (await import('./providers/youtube/templates/archive/wall/App.svelte')).default,
		grid: (await import('./providers/youtube/templates/archive/grid/App.svelte')).default,
		collage: (await import('./providers/youtube/templates/archive/collage/App.svelte')).default,
		slider: (await import('./providers/youtube/templates/archive/slider/App.svelte')).default,
		carousel: (await import('./providers/youtube/templates/archive/carousel/App.svelte')).default,
	};

	// Twitch
	Templates['twitch'] = {
		live: (await import('./providers/twitch/templates/live/liveonly/App.svelte')).default,
		live_plus: (await import('./providers/twitch/templates/live/liveplus/App.svelte')).default,
		wall: (await import('./providers/twitch/templates/archive/wall/App.svelte')).default,
		grid: (await import('./providers/twitch/templates/archive/grid/App.svelte')).default,
		collage: (await import('./providers/twitch/templates/archive/collage/App.svelte')).default,
		slider: (await import('./providers/twitch/templates/archive/slider/App.svelte')).default,
		carousel: (await import('./providers/twitch/templates/archive/carousel/App.svelte')).default,
	};

	// Instagram
	Templates['instagram'] = {
		grid: (await import('./providers/instagram/templates/archive/grid/App.svelte')).default,
		wall: (await import('./providers/instagram/templates/archive/wall/App.svelte')).default,
		grid: (await import('./providers/instagram/templates/archive/grid/App.svelte')).default,
		collage: (await import('./providers/instagram/templates/archive/collage/App.svelte')).default,
		slider: (await import('./providers/instagram/templates/archive/slider/App.svelte')).default,
		carousel: (await import('./providers/instagram/templates/archive/carousel/App.svelte')).default,
	};

	// TikTok
	Templates['tiktok'] = {
		grid: (await import('./providers/tiktok/templates/archive/grid/App.svelte')).default,
		wall: (await import('./providers/tiktok/templates/archive/wall/App.svelte')).default,
		grid: (await import('./providers/tiktok/templates/archive/grid/App.svelte')).default,
		collage: (await import('./providers/tiktok/templates/archive/collage/App.svelte')).default,
		slider: (await import('./providers/tiktok/templates/archive/slider/App.svelte')).default,
		carousel: (await import('./providers/tiktok/templates/archive/carousel/App.svelte')).default,
	};
	
	(async() => {
		while(typeof $provider == 'undefined')
		await new Promise(resolve => setTimeout(resolve, 400));
		Template = Templates[$provider][$store.layout];
	})();

})
</script>

<!--
// HTML
---------------->
<svelte:head>
  	<link rel="stylesheet" href="{$appUrl}/embedjs/embedvidio.css?r={ Math.floor((Math.random() * 10000) + 1) }">
</svelte:head>

<div id="embedvidio-widget-{ $widgetId }" class="ev-target">
	{#await $response}
	<div class="loader fade-in-image" out:fade>
		<Loading />
	</div>
	{:then data}

		{#if data.enabled && !data.past_due && (data.on_trial || data.subscribed) }

			<div in:fade={{ delay: 500 }}>
				<svelte:component  this="{Template}"  />
			</div>
		
		{:else}
			<!-- Not subscribed or trial ended -->
			<div in:fade={{ delay: 500 }}>
			<NoAccess />
			</div>
		{/if}

	{:catch} 
		<Error/>
	{/await}
</div>


<!--
// Styles
---------------->
<style>
.ev-target{
	font-family:Arial, Helvetica, sans-serif !important;
}
.loader{
	height:400px;
	position: relative;
}
</style>