<!--
// Javascript
---------------->
<script>
import { Splide, SplideTrack, SplideSlide } from '@splidejs/svelte-splide';
import '@splidejs/svelte-splide/css';
import Header from "../../../../../providers/instagram/templates/components/Header.svelte"
import SocialLogo from "../../../../../components/logos/Instagram.svelte"
import { store, videos } from '../../../../../stores/widget.js'
</script>


<!--
// HTML
---------------->
<div>
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.instagram_meta.picture}
        headerTitle={$store.instagram_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.instagram_meta.username}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
    <Splide hasTrack={ false } aria-label="Facebook Videos"
            options={ {
                perPage: 4,
                rewind: true,
                perMove: 1,
                pagination: false,
                gap: '1rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }}}>
        <div class="ev-custom-wrapper">

            <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
            </div>
            <SplideTrack>
                {#each $videos as {picture, username, description, ig_created_at}, i}
                <SplideSlide>

                    <div class="ev-card-container" style="background-image: url({picture});">
                        <div class="ev-card-body ev-card-grid-overlay">
                            <div id="{i}" on:click class="ev-link-cover"></div>
                            <div class="ev-meta">
                                <span class="ev-logo">
                                    <SocialLogo />
                                </span>
                                <span class="ev-date">{ig_created_at}</span>
                                <span class="ev-title ev-title-clamp">{username}</span>
                                <span class="ev-description ev-description-clamp">{description ? description : '...'}</span>
                            </div>
                        </div>
                        <div class="ev-meta-bottom">
                            <div class="ev-meta-logo-bottom">
                                <SocialLogo />
                            </div>
                            <div class="ev-meta-title-bottom">
                                {username}
                                <div class="ev-meta-date-bottom">{ig_created_at}</div>
                            </div>
                        </div>
                    </div>

                </SplideSlide>
            {/each}
            </SplideTrack>
            <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--next">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>
            
        </div>
    </Splide>
</div>


<!--
// Styles
---------------->
<style>
.ev-custom-wrapper svg {
	fill: none;
	transform: scaleX(1);
	color: #000;
}
.ev-card-container {
    border: 1px solid #EAEAEA;
    border-radius:0.8rem;
    background-repeat: no-repeat;
        background-position: center;
    background-size:cover;
    width:100%;
}
.ev-card-grid-overlay {
    position: relative;
    transition: .5s ease;
    border-radius:0.8rem;
    min-height: 250px;
    opacity: 0;
    z-index: 2;
}
.ev-link-cover{
    background: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    /* z-index: 999; */
    border-radius:0.8rem;
    cursor: pointer;
}
.ev-card-grid-overlay:hover {
    background: rgba(0,0,0,0.9);	
}
.ev-card-container:hover .ev-card-grid-overlay {
        opacity: 1;
}
.ev-card-body{
    display: grid;
    text-align: center;
    /* padding: 20px; */
}
.ev-meta{
    margin: auto;
	word-break: break-word;
	/* add */
	padding: 20px;
}
.ev-logo{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.ev-date{
    color: rgba(255, 255, 255, 0.3);
    font-weight:300;
    font-size: small;
}
.ev-title{
    margin: 15px 0px 10px 0px;
    color: #FFFFFF;
    font-weight:300;
}
.ev-description{
    color: #FFFFFF;
    font-weight:300;
    font-size: small;
    margin-bottom: 20px;
}
.ev-title-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.ev-description-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ev-meta-bottom{
    position: absolute;
    bottom:0;
    z-index: 1;
    width:100%;
    /* padding:10px; */
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    background-image:linear-gradient(180deg,transparent,#000);
    text-align: left;
}
.ev-meta-logo-bottom{
    float:right;
	/* padding: 8px 5px 0 0; */
	/* change */
	padding: 20px 15px 0 0;
}
.ev-meta-title-bottom{
    color: rgba(255, 255, 255, 0.9);
    font-size:14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
	/* add */
	padding:10px;
}
.ev-meta-date-bottom{
    color: rgba(255, 255, 255, 0.6);
    font-weight:300;
    font-size: 12px;
}
@media only screen and (max-width: 768px){
    .ev-meta-bottom{
        width:100%;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
    .ev-card-container {
        max-width:100%;
    }
}
</style>