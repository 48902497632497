<svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="TikTok logo.">
    <g clip-path="url(#tiktokClip0)">
        <path d="M23.3411 5.7493C22.2479 4.50395 21.6452 2.90303 21.6458 1.24561H20.324C20.4947 2.16197 20.8503 3.03393 21.369 3.80825C21.8877 4.58257 22.5587 5.24302 23.3411 5.7493V5.7493ZM11.1102 15.4943C10.1831 15.499 9.28426 15.8137 8.55647 16.3882C7.82868 16.9628 7.31376 17.7642 7.09356 18.6652C6.87337 19.5661 6.96054 20.5149 7.34123 21.3606C7.72191 22.2062 8.37426 22.9003 9.19455 23.3325C8.74606 22.713 8.47748 21.9815 8.41849 21.2188C8.3595 20.4562 8.5124 19.692 8.86028 19.0108C9.20817 18.3297 9.7375 17.758 10.3898 17.3589C11.0421 16.9598 11.792 16.7489 12.5566 16.7495C12.9722 16.7549 13.385 16.8197 13.7824 16.9417V11.901C13.3766 11.8401 12.9669 11.8081 12.5566 11.8052H12.3362V15.638C11.9367 15.5308 11.5237 15.4824 11.1102 15.4943V15.4943Z" fill="#FE2C55"></path>
        <path d="M12.3353 11.8147V10.6456C11.9297 10.5814 11.52 10.5462 11.1093 10.5403C7.13373 10.5318 3.61405 13.1093 2.42018 16.9031C1.2263 20.6969 2.63553 24.8267 5.89875 27.0982C4.70212 25.817 3.90174 24.2166 3.59421 22.4903C3.28668 20.764 3.48516 18.9855 4.16568 17.3696C4.84621 15.7536 5.97969 14.3693 7.4293 13.3836C8.87892 12.3979 10.5827 11.8531 12.335 11.8148L12.3353 11.8147Z" fill="#25F4EE"></path>
        <path d="M12.5574 25.0573C14.7802 25.0543 16.6073 23.3024 16.7046 21.0807V1.25531H20.3248C20.2509 0.841037 20.2157 0.420795 20.2195 0L15.2678 0V19.8063C15.1854 22.0393 13.354 23.808 11.1206 23.8116C10.4532 23.8059 9.79665 23.6417 9.20508 23.3325C9.58849 23.864 10.092 24.2972 10.6746 24.597C11.2572 24.8967 11.9023 25.0544 12.5574 25.0573V25.0573ZM27.0867 7.98186V6.87992C25.7544 6.88044 24.4516 6.48711 23.3419 5.74932C24.3146 6.8807 25.6292 7.6644 27.0867 7.98186V7.98186Z" fill="#25F4EE"></path>
        <path d="M27.0827 7.98193V11.8149C24.6162 11.8101 22.2137 11.0289 20.2155 9.58218V19.653C20.2049 24.6783 16.13 28.7465 11.1071 28.7465C9.24156 28.7499 7.42099 28.174 5.89648 27.0984C7.13963 28.4361 8.75662 29.3683 10.5368 29.7735C12.3171 30.1787 14.178 30.0382 15.8773 29.3702C17.5765 28.7022 19.0353 27.5378 20.0637 26.0285C21.092 24.5193 21.6422 22.7352 21.6426 20.9086V10.8663C23.6475 12.3035 26.053 13.0744 28.5194 13.0702V8.1349C28.0365 8.13346 27.5551 8.0822 27.0827 7.98193V7.98193Z" fill="#FE2C55"></path>
        <path d="M20.2184 19.6529V9.58209C22.2227 11.0206 24.6286 11.7917 27.0952 11.786V7.95319C25.638 7.6453 24.3203 6.8718 23.3408 5.7493C22.5585 5.24302 21.8875 4.58257 21.3688 3.80825C20.85 3.03392 20.4944 2.16197 20.3237 1.24561H16.7035V21.081C16.6683 21.9444 16.3651 22.7754 15.836 23.4584C15.307 24.1415 14.5784 24.6427 13.7516 24.8924C12.9247 25.1422 12.0406 25.128 11.2221 24.8519C10.4037 24.5758 9.69153 24.0515 9.18463 23.3519C8.3642 22.9198 7.71172 22.2257 7.33094 21.3799C6.95016 20.5342 6.86293 19.5853 7.08314 18.6843C7.30335 17.7833 7.81835 16.9817 8.54624 16.4072C9.27413 15.8326 10.1731 15.518 11.1003 15.5134C11.5161 15.5171 11.9291 15.5817 12.3263 15.7051V11.8722C10.5645 11.9021 8.84954 12.4442 7.39042 13.4323C5.9313 14.4205 4.79108 15.812 4.10878 17.4372C3.42648 19.0625 3.23159 20.8513 3.54787 22.5854C3.86415 24.3196 4.67793 25.9242 5.88998 27.2036C7.42947 28.2438 9.25263 28.7827 11.11 28.7464C16.1329 28.7464 20.2078 24.6782 20.2184 19.6529V19.6529Z" fill="black"></path>
    </g>
    <defs>
        <clipPath id="tiktokClip0">
            <rect width="42" height="42" fill="white"></rect>
        </clipPath>
    </defs>
</svg>

<!--
// Styles
---------------->
<style>
svg{
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 1px;
}
</style>