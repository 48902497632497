<!--
// Scripts
---------------->
<script>
import { onMount } from 'svelte';
import Header from "../../../../../providers/twitch/templates/components/Header.svelte"
import Branding from "../../../../../components/Branding.svelte";
import { store, providerObject } from '../../../../../stores/widget.js'

export let widget;
export let liveVideo;
export let branding;

onMount(() => {
    (async() => {
        while(typeof Twitch == 'undefined')
        await new Promise(resolve => setTimeout(resolve, 1000));
        (async() => {
            while(typeof liveVideo == 'undefined')
            await new Promise(resolve => setTimeout(resolve, 1000));

            let player = new Twitch.Embed("embedvidio-" + widget + "-twitch-embed", {
                muted: false,
                autoplay: true,
                width: '100%',
                height: '600',
                channel: liveVideo,
                layout: 'video'
            });
            providerObject.set(player)
        })();

    })();
})
</script>


<!--
// HTML
---------------->
<!-- Header -->
{#if $store.header_data.header == 1 }
<Header 
    headerThumbnail={$store.twitch_meta.picture}
    headerTitle={$store.twitch_meta.title}
    headerTitleColor={$store.header_data.header_txt_color}
    headerAccountId={$store.twitch_meta.title}
    customBtn={$store.header_data.custom_btn.active}
    customBtnText={$store.header_data.custom_btn.text}
    customBtnTextColor={$store.header_data.custom_btn.text_color}
    customBtnUrl={$store.header_data.custom_btn.url}
    customBtnColor={$store.header_data.custom_btn.color}
/>
{/if}
<div>
    <script src="https://player.twitch.tv/js/embed/v1.js"></script>
    <div id="embedvidio-{ widget }-twitch-embed"></div>
</div>
{#if branding == 1}
    <Branding />
{/if}


<!--
// Styles
---------------->