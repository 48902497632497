<!--
// Scripts
---------------->
<script>
    export let permalink;
</script>

<!--
// HTML
---------------->
<div class="ev-video">
    <video 
        class="" 
        src="{permalink}" 
        poster="" 
        controls="controls"
        autoplay="autoplay"
        disablepictureinpicture="" 
        controlslist="nodownload" 
        preload="" 
        tabindex="-1">
        <track kind="captions">
    </video>
</div>


<!--
// Styles
---------------->
<style>
.ev-video {
    position: relative;
    max-height: 443px; 
    height: 443px;
    background: #eef1f7;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    border-radius: 8px;
    overflow: hidden;
}
.ev-video video {
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}
</style>