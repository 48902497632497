<!--
// Javascript
---------------->
<script>
import Header from "../../../../../providers/twitch/templates/components/Header.svelte"
import SocialLogo from "../../../../../components/logos/TwitchW.svelte"
import { store, videos } from '../../../../../stores/widget.js'
</script>


<!--
// HTML
---------------->
<div>
    <!-- Header -->
    {#if $store.header_data.header == 1 }
    <Header 
        headerThumbnail={$store.twitch_meta.picture}
        headerTitle={$store.twitch_meta.title}
        headerTitleColor={$store.header_data.header_txt_color}
        headerAccountId={$store.twitch_meta.title}
        customBtn={$store.header_data.custom_btn.active}
        customBtnText={$store.header_data.custom_btn.text}
        customBtnTextColor={$store.header_data.custom_btn.text_color}
        customBtnUrl={$store.header_data.custom_btn.url}
        customBtnColor={$store.header_data.custom_btn.color}
    />
    {/if}
    <div class="ev-cards">
        {#each $videos as {picture, title, description, user_name, published_at}, i}
        <div class="ev-card-container" style="background-image: url({picture});">
            <div  class="ev-card-body ev-card-grid-overlay">
                <div id="{i}" on:click class="ev-link-cover"></div>
                <div class="ev-meta">
                    <span class="ev-logo">
                        <SocialLogo />
                    </span>
                    <span class="ev-date">{published_at}</span>
                    <span class="ev-title ev-title-clamp">{title ? title : user_name}</span>
                    <span class="ev-description ev-description-clamp">{description ? description : '...'}</span>
                </div>
            </div>
            <div class="ev-meta-bottom">
                <div class="ev-meta-logo-bottom">
                    <SocialLogo />
                </div>
                <div class="ev-meta-title-bottom">
                    {title ? title : user_name}
                    <div class="ev-meta-date-bottom">{published_at}</div>
                </div>
            </div>
        </div>
        {/each}
    </div>
</div>


<!--
// Styles
---------------->
<style>
.ev-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 250px;
    gap: 1rem;
    position: relative;
    /* max-width: 1200px;
    margin: 0 auto; */
}
.ev-card-container {
    position: relative;
    border: 1px solid #EAEAEA;
    border-radius:0.8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
}
.ev-card-grid-overlay {
    position: relative;
    transition: .5s ease;
    border-radius:0.8rem;
    height:100%;
    opacity: 0;
    z-index: 2;
}
.ev-link-cover{
    background: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    /* z-index: 1; */
    border-radius:0.8rem;
    cursor: pointer;
}
.ev-card-grid-overlay:hover {
    background: rgba(0,0,0,0.9);	
}
.ev-card-container:hover .ev-card-grid-overlay {
        opacity: 1;
}
.ev-card-body{
    display: grid;
    text-align: center;
    /* padding: 20px; */
}
.ev-meta{
    margin: auto;
	word-break: break-word;
	/* add */
	padding: 20px
}
.ev-logo{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}
.ev-date{
    color: rgba(255, 255, 255, 0.3);
    font-weight:300;
    font-size: small;
}
.ev-title{
    margin: 15px 0px 10px 0px;
    color: #FFFFFF;
    font-weight:300;
}
.ev-description{
    color: #FFFFFF;
    font-weight:300;
    font-size: small;
    margin-bottom: 20px;
}
.ev-title-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.ev-description-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.ev-meta-bottom{
    position: absolute;
    bottom:0;
    z-index: 1;
    width:100%;
    /* padding:10px; */
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    background-image:linear-gradient(180deg,transparent,#000);
    text-align: left;
}
.ev-meta-logo-bottom{
    float:right;
	/* padding: 8px 5px 0 0; */
	/* change */
	padding: 20px 15px 0 0;
    opacity: .7;
}
.ev-meta-title-bottom{
    color: rgba(255, 255, 255, 0.9);
    font-size:14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75%;
	/* add */
	padding:10px;
}
.ev-meta-date-bottom{
    color: rgba(255, 255, 255, 0.6);
    font-weight:300;
    font-size: 12px;
}
@media only screen and (max-width: 768px){
    .ev-meta-bottom{
        width:100%;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
}
</style>