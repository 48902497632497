<!--
// Scripts
---------------->
<script>

</script>


<!--
// HTML
---------------->
<div>
    <!-- <div class="notification-top-bar">
        <p>Oracular IS Completes Acquisition of Logixify Inc. See the <small><a href="https://www.yahoo.com">Press Release</a></small></p>
    </div> -->
    <div class="toast">
        <div class="toast-body">
            We are live now
        </div>
    </div>
</div>

<!--
// Styles
---------------->
<style>
.toast {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.toast-body {
    margin: 60px;
    padding: 20px 24px;
    font-size: larger;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: lightskyblue;
    color: darkblue;
    border-radius: 4px;
}
/* .notification-top-bar {
    height: 40px;
    line-height: 40px;
    width: 100%;
    background: #1ABC9C;
    text-align: center;
    color: #FFFFFF;
    font-family: sans-serif;
    font-weight: lighter;
    font-size: 14px;
}
.notification-top-bar p {
    padding: 0;
    margin: 0;
}
.notification-top-bar p a {
    padding: 5px 10px;
    border-radius: 3px;
    background: #FFF;
    color: #1ABC9C;
    font-weight: bold;
    text-decoration: none;
} */
</style>