<!--
// Javascript
---------------->
<script>
// import { fade } from 'svelte/transition';
import SocialLogo from "../../../../../../components/logos/Instagram.svelte"
import { createEventDispatcher } from 'svelte';
const dispatch = createEventDispatcher();

export let videoId;
export let permalink;
export let userName;
export let description;
export let publishedAt;

// let width;
let height;

</script>

<!--
// HTML
---------------->
<div class="ev-container">

    <div>
        <a href="#prev" on:click={() => dispatch('prevClick')} class="ev-prev">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>       
        </a>
    </div>

    <div class="ev-slide">
        <div class="ev-content">

            <div class="ev-video">
                <video 
                    class="" 
                    src="{videoId}" 
                    poster="" 
                    controls="controls"
                    autoplay="autoplay"
                    disablepictureinpicture="" 
                    controlslist="nodownload" 
                    preload="" 
                    tabindex="-1">
                    <track kind="captions">
                </video>
            </div>


        </div>
        <div class="ev-meta">
            <div class="ev-logo">
                <a href="{permalink}" target="_blank">
                    <SocialLogo />
                </a>
            </div>
            <div>
                <a class="ev-name" href="https://www.instagram.com/{userName}" target="_blank">
                    {userName}
                </a>
                <div class="ev-date">{publishedAt}</div>
            </div>
            <div>
                <div class="ev-description">
                    {description ? description : '...'}
                </div>
            </div>
        </div>   
    </div>

    <div>
        <a href="#next" on:click={() => dispatch('nextClick')} class="ev-next">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg> 
        </a>
    </div>

</div>


<!--
// Styles
---------------->
<style>
.ev-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ev-slide {
    display: grid;
    grid-template-columns: 60fr 40fr;
    grid-gap: 1rem;
    padding:20px;
    background: #ffffff;
    border-radius: 10px;
}
.ev-next,
.ev-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(138, 138, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    margin:0px 20px;
    opacity:.4;
    padding: 4px;
}
.ev-next:hover,
.ev-prev:hover {
    background-color: rgb(255, 255, 255);
    opacity:.8;
}
.ev-content {
    display: grid;
}
.ev-video{
    position: relative;
    background: #eef1f7;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    border-radius: 8px;
    overflow: hidden;
}
video{
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
}
.ev-meta{
    min-width: 400px;
    min-height: 700px;
    padding: 0px 10px;
    text-align: left;
}
.ev-name{
    color: rgb(37, 99,235);
    font-size:larger;
    font-weight: 600;
}
.ev-name:hover{
    color: rgb(63, 115, 228);
}
.ev-date{
    margin-top: -2px;
    margin-left:3px;
    color: #9ca0ac;
    font-weight:300;
    font-size: small;
}
.ev-logo{
    float:right
}
.ev-description {
    color: rgb(107, 114, 128);
    font-size: 14px;
    line-height: 1.5;
    margin:20px 0px 10px 0px;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
}
@media only screen and (max-width: 1200px){
    .ev-slide{
        grid-template-columns: 1fr;
        padding:0px;
        background: #ffffff;
    }
    .ev-next,
    .ev-prev{
        display:none;
    }
    .ev-meta{
        padding:20px;
    }
    .ev-video{
        border-radius: 0px;
    }
    video{
        position:unset;
        -ms-transform: unset;
        -webkit-transform: unset;
        transform: unset;
    }
}
</style>